import {Fragment, useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid, InputLabel,
    ListSubheader, MenuItem, Select,
    TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {useGetAbtestParametersQuery} from "../../../core/reducers/abtest";
import {getCurrentParam, getCurrentParamAllowedValues} from "../../Campaign/CampaignForm";
import StyledFormGroup from "../StyledFormGroup";
import {getEnvName, isEmpty} from "../../Utils/CommonUtils";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import Loading from "../../Loading";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {useGetAllCampaignTypesQuery} from "../../../core/reducers/campaignType";

const NUMBER_VERSION_OPERATORS = [
    'gt', 'lt', 'ge', 'le'
];
export const POSSIBLE_OPERATORS: Map = new Map(Object.entries({
    'NUMBER_RANGE': NUMBER_VERSION_OPERATORS,
    'VERSION_RANGE': NUMBER_VERSION_OPERATORS,
    'INCLUDE': 'include',
    'INCLUDE_ALL': 'includeAll',
    'INCLUDE_ANY': 'includeAny',
    'EXCLUDE_ALL': 'excludeAll',
    'EXCLUDE_ANY': 'excludeAny',
    'OFFER_IN_PROFILE': 'offerInProfile',
    'OFFER_NOT_IN_PROFILE': 'offerNotInProfile',
    'PROFILE_OFFSET': 'profileOffset',
    'OFFER_INACTIVE': 'offerInactiveRule',
    'TYPE_INACTIVE': 'offerTypeInactiveRule'

}));

export default function AudienceStep(props) {
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [searchParams, setSearchParams] = useSearchParams();
    const env = getEnvName('env', sourceMode, searchParams);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    const [selectedAbTestCohortValue, setSelectedAbTestCohortValue] = useState({});

    const OFFERS_PARAMETERS = [
        'offers_in_profile', 'offers_not_in_profile'
    ]
    const OFFSET_PARAMETERS = ['profile_offset_from_end', 'profile_offset_from_start']
    const [currentAudienceIndex, setCurrentAudienceIndex] = useState(0);
    const {
        data: params,
        status: paramsStatus,
        isLoading,
        isError,
        error
    } = useGetAbtestParametersQuery({env: env})

    useEffect(() => {
        if(paramsStatus === 'fulfilled' && props.currentAbTest.audiences[currentAudienceIndex] == undefined) {
            console.log(currentAudienceIndex + ' not found')
        }
        if (paramsStatus === 'fulfilled' && props.currentAbTest.audiences[currentAudienceIndex]?.parameters[0]?.parameterData.parameterName === undefined) {
            // props.handleParameterDataChange(params, params[0].parameterName, 0, currentAudienceIndex)
        }
    }, [paramsStatus])

    const getCurrentParamName = (paramIndex) => {
        const currentParam = getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex);
        return currentParam.parameterData.parameterName
    }
    const getCurrentParamOperators = (params, paramName) => {
        if (!params || !paramName) return []
        let currentParameter = params.filter(p => p.parameterName === paramName);
        if(currentParameter.length == 0) return []
        currentParameter = currentParameter[0];
        return currentParameter.allowedRules.flatMap(rule => POSSIBLE_OPERATORS.get(rule))
    }

    const handleDecrementCurrentAudienceIndex = (index) => {
        if (index > currentAudienceIndex) {
            return
        }
        if (currentAudienceIndex > 1) {
            setCurrentAudienceIndex(currentAudienceIndex - 1);
        } else {
            setCurrentAudienceIndex(0)
        }
    }

    const isVersionNumericParameter = (paramIndex) => {
        const result = getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).hasOwnProperty('fromIncl') ||
            getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).hasOwnProperty('toIncl');
        return result;
    }

    const isOffersParameter = (paramIndex) => {
        const currentParam = getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex);
        return OFFERS_PARAMETERS.includes(currentParam?.parameterData?.parameterName);
    }
    const isOffsetParameter = (paramIndex) => {
        const currentParam = getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex);
        return OFFSET_PARAMETERS.includes(currentParam?.parameterData?.parameterName);
    }

    const hasError = (audienceIndex, paramIndex, errorType) => {
        let typeErrors = props.errorFields.filter(e => e.hasOwnProperty(errorType));
        if (typeErrors.length === 0) return false;
        typeErrors = typeErrors[0]

        return typeErrors[errorType].filter(e => e.audienceIndex === audienceIndex &&
            e.paramIndex === paramIndex
        ).length > 0
    }

    function getOffsetInputComponentOrDefault(paramIndex, currentAudienceIndex) {
        const currentParam = getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex)
        if (!isOffsetParameter(paramIndex)) {
            if(['includeAny','excludeAll','excludeAny','includeAll'].includes(currentParam.operator) ) {
                return <Autocomplete
                    fullWidth
                    freeSolo={true}
                    autoWidth={true}
                    limitTags={1}
                    multiple

                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                    value={currentParam.paramValue ?
                        currentParam: []}

                    onChange={(event, value) =>
                        props.handleParameterValueChange(value, paramIndex, currentAudienceIndex)}
                    onBlur={(event) => {
                        const currentValue = event.target.value.trim(); // убираем пробелы
                        const currentParamValue = getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).paramValue || [];

                        if (currentValue && !currentParamValue.includes(currentValue)) {
                            // Если текущее значение не дублируется, добавляем его
                            const updatedValue = [...currentParamValue, currentValue];
                            props.handleParameterValueChange(updatedValue, paramIndex, currentAudienceIndex);
                        }
                    }}
                    renderInput={
                        params => (
                            <TextField
                                error={!!getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).valueError}
                                {...params}
                                id="value"
                                name="value"
                                label="Value (press enter to split)"
                                variant="outlined"


                            />
                        )
                    }>

                </Autocomplete>
            }
            return <TextField
                fullWidth
                // error={!!getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).valueError}
                error={hasError(currentAudienceIndex, paramIndex, 'paramValueError')}
                id="value"
                name="value"
                label="Value"
                variant="outlined"
                value={currentParam.paramValue}
                onChange={(e) => props.handleManualInput(e, paramIndex, currentAudienceIndex)}
            />
        }

        return <Grid container>

            <Grid sm={5} pr={0.5}>
                <TextField
                    fullWidth
                    error={!!currentParam.offerValueError}
                    id="offerId"
                    name='offerId'
                    label="Offer id"
                    variant="outlined"
                    value={currentParam.offerId}
                    onChange={(e) => props.handleManualInputForOffset(e, 'offerId', paramIndex, currentAudienceIndex)}
                />
            </Grid>
            <Grid sm={2} pr={0.5}>
                <FormControl fullWidth variant="outlined" error={!!currentParam.operatorValueError}>
                    <InputLabel id={`operator-label-${paramIndex}-${currentAudienceIndex}`}>Operator</InputLabel>
                    <Select
                        labelId={`operator-label-${paramIndex}-${currentAudienceIndex}`}
                        id="operator"
                        name="operator"
                        value={currentParam.operator}
                        onChange={(e) => props.handleManualInputForOffset(e, 'operator', paramIndex, currentAudienceIndex)}
                        label="Operator"
                        error={isEmpty(currentParam.operator)}

                    >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="GREATER_THAN">{">"}</MenuItem>
                        <MenuItem value="LESS_THAN">{"<"}</MenuItem>
                    </Select>
                </FormControl>

            </Grid>
            <Grid sm={5} pl={0.5}>
                <TextField
                    fullWidth
                    error={!!currentParam.offsetValueError}
                    id="offsetValue"
                    name="offsetValue"
                    label="Offset value (for example PT24H)"
                    variant="outlined"
                    value={currentParam.offset}
                    onChange={(e) => props.handleManualInputForOffset(e, 'offset', paramIndex, currentAudienceIndex)}
                />
            </Grid>
        </Grid>
    }

    const typeError = (value) => {
        return isEmpty(value?.parameterData?.parameterName);
    }

    const filterAbTestCohorts = (parameterName, aIndex, options) => {
        if (parameterName !== 'ab_test_cohort' || isEmpty(selectedAbTestCohortValue[aIndex])) {
            return options;
        }
        return options.filter(o => {
            debugger
            return o.split(":")[0]===selectedAbTestCohortValue[aIndex]
        })
    }

    const {
        data: types,
        status: typeStatus
    } = useGetAllCampaignTypesQuery()

    const SHORT_CAMPAIGN_NAMES = types!=undefined ? Object.keys(types).reduce((acc, item) => {
            const abbreviation = types[item].metaData?.abbreviation;
            if (abbreviation) {
                acc[item] = abbreviation;
            }
            return acc;
        }, {})
        : {};


    const createParameterComponent = (parameters, paramIndex) => {
        return (
            <>
                {params && <Grid item xs={3}>
                    <Autocomplete
                        disableClearable={true}
                        options={params}
                        groupBy={(option) => option.isMain ? 'POPULAR' : 'OTHER'}
                        renderGroup={(params) => (
                            <Fragment key={params.key}>
                                <ListSubheader>{params.group}</ListSubheader>
                                {params.children}
                            </Fragment>
                        )}
                        getOptionLabel={(option) => option.parameterName!=undefined?option.parameterName:''}

                        value={getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex) != undefined ? getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).parameterData : ''}

                        onChange={(event, value) =>
                            props.handleParameterDataChange(params, value.parameterName, paramIndex, currentAudienceIndex)
                        }
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    id="parameterData"
                                    name="parameterData"
                                    variant="outlined"
                                    label="Requirement"
                                    error={hasError(currentAudienceIndex, paramIndex, 'sameFieldError', 'paramNameError') ||
                                        // typeError(getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex))
                                        typeError(props.currentAbTest.audiences[currentAudienceIndex].parameters[paramIndex])}
                                />
                            )
                        }>
                    </Autocomplete>
                </Grid>}
                {!isOffersParameter(paramIndex) && !isOffsetParameter(paramIndex)
                    && !['type_inactive','offer_inactive'].includes(getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).parameterData.parameterName)
                    &&
                    <Grid item xs={!isVersionNumericParameter(paramIndex) ? 2 : 8}>
                        {!isVersionNumericParameter(paramIndex) &&
                            <Autocomplete
                                freeSolo={['offer_inactive','type_inactive'].includes(getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).parameterData.parameterName)}
                                disableClearable={true}
                                options={getCurrentParamOperators(params, getCurrentParamName(paramIndex))}
                                value={getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).operator || ''}
                                onChange={(event, value) =>
                                    props.handleParameterOperatorChange(value, paramIndex, currentAudienceIndex)
                                }
                                renderInput={
                                    params => (
                                        <TextField
                                            {...params}
                                            id="operator"
                                            name="operator"
                                            label="Operator"
                                            variant="outlined"
                                            error={!!getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).operatorError}
                                        />
                                    )
                                }>

                            </Autocomplete>
                        }

                        {/*is version/numeric parameter*/}
                        {isVersionNumericParameter(paramIndex) &&
                            <StyledFormGroup
                                errorFields={props.errorFields}
                                paramIndex={paramIndex}
                                paramName={getCurrentParamName(paramIndex)}
                                currentItem={props.currentAbTest}
                                currentAudienceIndex={currentAudienceIndex}
                                handleParameterValueChange={props.handleParameterValueChange}
                                handleVersionNumericParameterChange={props.handleVersionNumericParameterChange}
                            />
                        }
                    </Grid>}
                {!isVersionNumericParameter(paramIndex) &&
                    <Grid item xs={isOffersParameter(paramIndex) || isOffsetParameter(paramIndex) ? 8 : 6}>
                        <Grid container xs={12}>
                            {getCurrentParamAllowedValues(props.currentAbTest, paramIndex, currentAudienceIndex, params).length !== 0 ||
                                (['includeAny','excludeAll','excludeAny','includeAll'].includes(getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).operator) )
                                ?
                                <Autocomplete
                                    fullWidth
                                    limitTags={1}

                                    freeSolo={['includeAny','excludeAll','excludeAny','includeAll'].includes(getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).operator)}
                                    getOptionLabel={(option) => (
                                        option + (SHORT_CAMPAIGN_NAMES.hasOwnProperty(option) ? ' (' + SHORT_CAMPAIGN_NAMES[option] + ')' : '')
                                    )}
                                    multiple={getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).parameterData.parameterName !== 'ab_test'}
                                    value={getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).paramValue ?
                                        getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).paramValue : []}
                                    options={getCurrentParamAllowedValues(props.currentAbTest, paramIndex, currentAudienceIndex, params) ?
                                        filterAbTestCohorts(
                                            getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).parameterData.parameterName,
                                            currentAudienceIndex,
                                            getCurrentParamAllowedValues(props.currentAbTest, paramIndex, currentAudienceIndex, params)
                                        ) : []}
                                    onChange={(event, value) => {
                                        if (getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).parameterData.parameterName === 'ab_test_cohort') {
                                            debugger
                                            value.length > 0 ? setSelectedAbTestCohortValue({[currentAudienceIndex] : value[0].split(":")[0]}) :
                                                setSelectedAbTestCohortValue({[currentAudienceIndex] : ""});
                                        }
                                        props.handleParameterValueChange(value, paramIndex, currentAudienceIndex)
                                        }
                                    }
                                    onBlur={(event) => {
                                        const currentValue = event.target.value.trim(); // убираем пробелы
                                        const currentParamValue = getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).paramValue || [];

                                        if (currentValue && !currentParamValue.includes(currentValue)) {
                                            // Если текущее значение не дублируется, добавляем его
                                            const updatedValue = [...currentParamValue, currentValue];
                                            props.handleParameterValueChange(updatedValue, paramIndex, currentAudienceIndex);
                                        }
                                    }}
                                    renderInput={
                                        params => (
                                            <TextField
                                                error={!!getCurrentParam(props.currentAbTest, paramIndex, currentAudienceIndex).valueError}
                                                {...params}
                                                id="value"
                                                name="value"
                                                label="Value (press enter to split)"
                                                variant="outlined"
                                            />
                                        )
                                    }>

                                </Autocomplete> : getOffsetInputComponentOrDefault(paramIndex, currentAudienceIndex)
                            }

                        </Grid>
                    </Grid>}
                <Grid item xs={1} display={'flex'} justifyContent={'start'}>
                    {parameters.length > 1 &&
                        <Button style={{minWidth: '100%'}}
                                variant={'outlined'}
                                color={'error'}
                                onClick={() => props.handleDeleteParams(currentAudienceIndex, paramIndex)}
                        >
                            <DeleteIcon/>
                        </Button>}
                </Grid>
            </>
        )
    }

    const _audiencesWithErrors = () => {

        let set = new Set();

        props.errorFields.forEach(e => {
            for (let field of Object.keys(e)) {
                for (let param of e[field]) {
                    set.add(param.audienceIndex)
                }
            }
        })
        return set;
    }

    return (
        <Fragment>
            <Grid container spacing={3} minWidth={'900px'}>
                <Grid container xs={12} pl={3} spacing={1}>
                    {props.currentAbTest.audiences.map((audience, index) => (
                        <Grid item key={index} xs={2}>
                            <Chip
                                icon={_audiencesWithErrors().has(index) ? <WarningRoundedIcon/> : null}
                                style={{
                                    borderRadius: "5px",
                                    height: '40px',
                                    width: '100%'
                                }}
                                variant={index === currentAudienceIndex ? 'contained' : 'outlined'}
                                label={audience.name}
                                color={ _audiencesWithErrors().has(index) ? 'error' : 'warning' }
                                onClick={() => setCurrentAudienceIndex(index)}
                                onDelete={
                                    props.currentAbTest.audiences.length > 1 ? () => {
                                        props.handleDeleteAudience(index)
                                        handleDecrementCurrentAudienceIndex(index);
                                    } : ""
                                }
                            />
                        </Grid>
                    ))}
                    <Grid item>
                        <Button
                            variant={'outlined'}
                            color={'warning'}
                            size={'small'}
                            onClick={() => {
                                props.handleAddNewAudience(props.currentAbTest.audiences.length)
                                setCurrentAudienceIndex(props.currentAbTest.audiences.length)
                            }
                            }
                            fullWidth
                            style={{
                                height: '40px'
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Loading isLoading={isLoading} isError={isError} error={error}>
                        <Grid container spacing={1}>

                            {props.currentAbTest.audiences[currentAudienceIndex]?.parameters && paramsStatus === 'fulfilled' &&
                                props.currentAbTest.audiences[currentAudienceIndex].parameters.map((paramSet, paramIndex) => {
                                    return createParameterComponent(props.currentAbTest.audiences[currentAudienceIndex].parameters, paramIndex)
                                })}
                            <Grid item xs={4} pt={2} pl={1}>
                                <Button
                                    variant={'outlined'}
                                    onClick={() => props.handleAddNewParams(currentAudienceIndex)}
                                >
                                    <AddIcon/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Loading>

                </Grid>

                <Grid item xs={12} style={{paddingTop: 3}} display={'flex'} justifyContent={'center'}>
                        <span style={{textDecoration: 'underline dotted', cursor: 'pointer'}}
                              onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                            advanced settings
                        </span>
                </Grid>
                {showAdvancedSettings &&
                    <Grid item xs={12}>

                        <FormControlLabel control={<Checkbox checked={props.currentAbTest.hasOwnProperty('fickle')&&props.currentAbTest.fickle} name={"fickle"} onChange={(e)=>{
                            props.setCurrentAbTest({
                                ...props.currentAbTest,
                                fickle: !props.currentAbTest.fickle
                            })
                        }
                        } />} label="Check Audiences Every Time (fickle)" />
                    </Grid>
                }


            </Grid>

        </Fragment>
    )
}