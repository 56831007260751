import {Fragment, useState} from "react";
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid, InputLabel,
    ListSubheader, MenuItem, Select,
    TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {getCurrentParam, getCurrentParamAllowedValues} from "../CampaignForm";
import StyledFormGroup from "../../Utils/StyledFormGroup";
import {useGetCampaignParametersQuery} from "../../../core/reducers/parameters";
import {useSelector} from "react-redux";
import {getEnvName} from "../../Utils/CommonUtils";
import {useSearchParams} from "react-router-dom";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Loading from "../../Loading";
import {useGetAllCampaignTypesQuery} from "../../../core/reducers/campaignType";

const NUMBER_VERSION_OPERATORS = [
    'gt', 'lt', 'ge', 'le'
];
export const POSSIBLE_OPERATORS: Map = new Map(Object.entries({
    'NUMBER_RANGE': NUMBER_VERSION_OPERATORS,
    'VERSION_RANGE': NUMBER_VERSION_OPERATORS,
    'INCLUDE': 'include',
    'INCLUDE_ANY': 'includeAny',
    'INCLUDE_ALL': 'includeAll',
    'EXCLUDE': 'exclude',
    'EXCLUDE_ANY': 'excludeAny',
    'EXCLUDE_ALL': 'excludeAll',
    'OFFER_IN_PROFILE': 'offerInProfile',
    'OFFER_NOT_IN_PROFILE': 'offerNotInProfile',
    'PROFILE_OFFSET': 'profileOffset',
    'TYPE_INACTIVE': 'offerTypeInactiveRule',
    'OFFER_INACTIVE': 'offerInactiveRule'
}));

export default function AudienceStep(props) {
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [searchParams, setSearchParams] = useSearchParams();
    const env = getEnvName('env', sourceMode, searchParams);
    const [errorAudienceList, setErrorAudienceList] = useState([]);

    const OFFERS_PARAMETERS = [
        'offers_in_profile', 'offers_not_in_profile'
    ]

    const DICTIONARY_REQUIRED_PARAMS = ['ab_test_cohort']

    const OFFSET_PARAMETERS = ['profile_offset_from_end', 'profile_offset_from_start']
    const [currentAudienceIndex, setCurrentAudienceIndex] = useState(0);
    const {
        data: params,
        status: paramsStatus,
        isLoading,
        isError,
        error
    } = useGetCampaignParametersQuery({env: env})



    const {
        data: types,
        status: typeStatus
    } = useGetAllCampaignTypesQuery()

    const SHORT_CAMPAIGN_NAMES = types!=undefined ? Object.keys(types).reduce((acc, item) => {
            const abbreviation = types[item].metaData?.abbreviation;
            if (abbreviation) {
                acc[item] = abbreviation;
            }
            return acc;
        }, {})
        : {};


    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    const getCurrentParamName = (paramIndex) => {
        const currentParam = getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex);
        return currentParam.parameterData.parameterName
    }
    const getCurrentParamOperators = (params, paramName) => {
        if (!params || !paramName) return []
        let currentParameter = params.filter(p => p.parameterName === paramName)
        if(currentParameter.length == 0) return []
        currentParameter = currentParameter[0];
        return currentParameter.allowedRules.flatMap(rule => POSSIBLE_OPERATORS.get(rule))
    }

    const handleDecrementCurrentAudienceIndex = (index) => {
        if (index > currentAudienceIndex) {
            return
        }
        if (currentAudienceIndex > 1) {
            setCurrentAudienceIndex(currentAudienceIndex - 1);
        } else {
            setCurrentAudienceIndex(0)
        }
    }

    const isVersionNumericParameter = (paramIndex) => {
        const result = getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).hasOwnProperty('fromIncl') ||
            getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).hasOwnProperty('toIncl');
        return result;
    }
    const isFreeSolo = (paramIndex) => {
        const result = getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).hasOwnProperty('fromIncl') ||
            getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).hasOwnProperty('toIncl');
        return result;
    }

    const isOffersParameter = (paramIndex) => {
        const currentParam = getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex);
        return OFFERS_PARAMETERS.includes(currentParam?.parameterData?.parameterName);
    }
    const isOffsetParameter = (paramIndex) => {
        const currentParam = getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex);
        return OFFSET_PARAMETERS.includes(currentParam?.parameterData?.parameterName);
    }

    function getOffsetInputComponentOrDefault(paramIndex, currentAudienceIndex) {
        const currentParam = getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex)
        if (!isOffsetParameter(paramIndex)) {
            return <TextField
                fullWidth
                error={!!currentParam.valueError}
                id="value"
                name="value"
                label="Value"
                variant="outlined"
                value={currentParam.paramValue}
                onChange={(e) => props.handleManualInput(e, paramIndex, currentAudienceIndex)}
            />
        }

        return <Grid container>
                <Grid sm={4} pr={0.5}>
                    <TextField
                        fullWidth
                        error={!!currentParam.offerValueError}
                        id="offerId"
                        name='offerId'
                        label="Offer id"
                        variant="outlined"
                        value={currentParam.offerId}
                        onChange={(e) => props.handleManualInputForOffset(e, 'offerId', paramIndex, currentAudienceIndex)}
                    />
                </Grid>
            <Grid sm={2} pr={0.5}>
                <FormControl fullWidth variant="outlined" error={!!currentParam.operatorValueError}>
                    <InputLabel id={`operator-label-${paramIndex}-${currentAudienceIndex}`}>Operator</InputLabel>
                    <Select
                        labelId={`operator-label-${paramIndex}-${currentAudienceIndex}`}
                        id="operator"
                        name="operator"
                        value={currentParam.operator}
                        onChange={(e) => props.handleManualInputForOffset(e, 'operator', paramIndex, currentAudienceIndex)}
                        label="Operator"
                        error={currentParam.operator==''}
                    >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="GREATER_THAN">{">"}</MenuItem>
                        <MenuItem value="LESS_THAN">{"<"}</MenuItem>
                    </Select>
                </FormControl>

            </Grid>
                <Grid sm={6} pl={0.5}>
                    <TextField
                        fullWidth
                        error={!!currentParam.offsetValueError}
                        id="offsetValue"
                        name="offsetValue"
                        label="Offset value (for example PT24H)"
                        variant="outlined"
                        value={currentParam.offset}
                        onChange={(e) => props.handleManualInputForOffset(e, 'offset', paramIndex, currentAudienceIndex)}
                    />
                </Grid>
            </Grid>
    }

    const createParameterComponent = (parameters, paramIndex) => {

        return (
            <>
                {params && <Grid item xs={3}>
                    <Autocomplete
                        disableClearable={true}
                        options={params}
                        groupBy={(option) => option.isMain ? 'POPULAR' : 'OTHER'}
                        renderGroup={(params) => (
                            <Fragment key={params.key}>
                                <ListSubheader>{params.group}</ListSubheader>
                                {params.children}
                            </Fragment>
                        )}
                        getOptionLabel={(option) => option.parameterName!=undefined?option.parameterName:''}

                        value={getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex) != undefined ? getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).parameterData : ''}

                        onChange={(event, value) =>
                            props.handleParameterDataChange(params, value.parameterName, paramIndex, currentAudienceIndex)
                        }
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    id="parameterData"
                                    name="parameterData"
                                    variant="outlined"
                                    label="Requirement"
                                    error={!!getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).paramNameError}
                                />
                            )
                        }>
                    </Autocomplete>
                </Grid>}
                {!isOffersParameter(paramIndex) && !isOffsetParameter(paramIndex)
                    && !['type_inactive','offer_inactive'].includes(getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).parameterData.parameterName)
                    &&
                    <Grid item xs={!isVersionNumericParameter(paramIndex) ? 2.5 : 8}>
                        {!isVersionNumericParameter(paramIndex)
                            &&
                            <Autocomplete
                                disableClearable={true}
                                options={getCurrentParamOperators(params, getCurrentParamName(paramIndex))}
                                value={getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).operator || ''}
                                onChange={(event, value) =>
                                    props.handleParameterOperatorChange(value, paramIndex, currentAudienceIndex)
                                }
                                renderInput={
                                    params => (
                                        <TextField
                                            {...params}
                                            id="operator"
                                            name="operator"
                                            label="Operator"
                                            variant="outlined"
                                            error={!!getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).operatorError}
                                        />
                                    )
                                }>

                            </Autocomplete>
                        }
                        {/*is version/numeric parameter*/}
                        {isVersionNumericParameter(paramIndex) &&
                            <StyledFormGroup
                                errorFeilds={props.errorFields}
                                setErrorFeilds={props.setErrorFields}
                                paramIndex={paramIndex}
                                paramName={getCurrentParamName(paramIndex)}
                                currentItem={props.currentCampaign}
                                currentAudienceIndex={currentAudienceIndex}
                                handleParameterValueChange={props.handleParameterValueChange}
                                handleVersionNumericParameterChange={props.handleVersionNumericParameterChange}
                            />
                        }
                    </Grid>}
                {!isVersionNumericParameter(paramIndex) &&
                    <Grid item xs={isOffersParameter(paramIndex) || isOffsetParameter(paramIndex) ? 8 : 5.5}>
                        <Grid container xs={12}>
                            {
                                ['includeAny','excludeAll','excludeAny','includeAll'].includes(getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).operator) ||
                                !isOffsetParameter(paramIndex)&&
                            (DICTIONARY_REQUIRED_PARAMS.includes(getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).parameterData.parameterName))
                            ||getCurrentParamAllowedValues(props.currentCampaign, paramIndex, currentAudienceIndex, params).length !== 0
                                ?
                                <Autocomplete
                                    fullWidth
                                    freeSolo={['offer_inactive','type_inactive','attribution_campaign'].includes(getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).parameterData.parameterName)}
                                    autoWidth={true}
                                    limitTags={1}
                                    multiple

                                    getOptionLabel={(option) => (
                                        option + (SHORT_CAMPAIGN_NAMES.hasOwnProperty(option) ? ' (' + SHORT_CAMPAIGN_NAMES[option] + ')' : '')
                                    )}

                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                    value={getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).paramValue ?
                                        getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).paramValue : []}
                                    options={getCurrentParamAllowedValues(props.currentCampaign, paramIndex, currentAudienceIndex, params) ? getCurrentParamAllowedValues(props.currentCampaign, paramIndex, currentAudienceIndex, params) : []}
                                    onChange={(event, value) =>
                                        props.handleParameterValueChange(value, paramIndex, currentAudienceIndex)}
                                    onBlur={(event) => {
                                        const currentValue = event.target.value.trim(); // убираем пробелы
                                        const currentParamValue = getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).paramValue || [];

                                        if (currentValue && !currentParamValue.includes(currentValue)) {
                                            // Если текущее значение не дублируется, добавляем его
                                            const updatedValue = [...currentParamValue, currentValue];
                                            props.handleParameterValueChange(updatedValue, paramIndex, currentAudienceIndex);
                                        }
                                    }}
                                    renderInput={
                                        params => (
                                            <TextField
                                                error={!!getCurrentParam(props.currentCampaign, paramIndex, currentAudienceIndex).valueError}
                                                {...params}
                                                id="value"
                                                name="value"
                                                label="Value (press enter to split)"
                                                variant="outlined"


                                            />
                                        )
                                    }>

                                </Autocomplete> : getOffsetInputComponentOrDefault(paramIndex, currentAudienceIndex)
                            }

                        </Grid>
                    </Grid>}
                <Grid item xs={1} display={'flex'} justifyContent={'start'}>
                    {parameters.length > 1 &&
                        <Button style={{minWidth: '100%'}}
                                variant={'outlined'}
                                color={'error'}
                                onClick={() => props.handleDeleteParams(currentAudienceIndex, paramIndex)}
                        >
                            <DeleteIcon/>
                        </Button>}
                </Grid>
            </>
        )
    }

    return (
        <Loading isLoading={isLoading} isError={isError} error={error}>
            <Grid container spacing={3} minWidth={'800px'}>
                <Grid container xs={12} pl={3} spacing={1}>
                    {props.currentCampaign.audiences.map((audience, index) => (
                        <Grid item key={index} xs={2}>
                            <Chip
                                icon={props.errorAudienceIndexes.includes(index) ? <WarningRoundedIcon/> : null}
                                style={{
                                    borderRadius: "5px",
                                    height: '40px',
                                    width: '100%'
                                }}
                                variant={index === currentAudienceIndex ? 'contained' : 'outlined'}
                                label={audience.name}
                                color = {props.errorAudienceIndexes.includes(index) ? 'error' : 'warning'}
                                onClick={() => setCurrentAudienceIndex(index)}
                                onDelete={
                                    props.currentCampaign.audiences.length > 1 ? () => {
                                        props.handleDeleteAudience(index)
                                        handleDecrementCurrentAudienceIndex(index);
                                    } : ""
                                }
                            />
                        </Grid>
                    ))}
                    <Grid item>
                        <Button
                            variant={'outlined'}
                            color={'warning'}
                            size={'small'}
                            onClick={() => {
                                props.handleAddNewAudience(props.currentCampaign.audiences.length)
                                setCurrentAudienceIndex(props.currentCampaign.audiences.length)
                            }
                            }
                            fullWidth
                            style={{
                                height: '40px'
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} container spacing={1}>
                        {props.currentCampaign.audiences[currentAudienceIndex]?.parameters && paramsStatus === 'fulfilled' &&
                            props.currentCampaign.audiences[currentAudienceIndex].parameters.map((paramSet, paramIndex) => {
                                return createParameterComponent(props.currentCampaign.audiences[currentAudienceIndex].parameters, paramIndex)
                            })}

                        <Grid item xs={4} pt={2} pl={1}>
                            <Button
                                variant={'outlined'}
                                onClick={() => props.handleAddNewParams(currentAudienceIndex)}
                            >
                                <AddIcon/>
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{paddingTop: 3}} display={'flex'} justifyContent={'center'}>
                        <span style={{textDecoration: 'underline dotted', cursor: 'pointer'}}
                              onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                            advanced settings
                        </span>
                    </Grid>

                    {showAdvancedSettings &&
                        <Grid>
                            <Grid item xs={12}>

                                <FormControlLabel control={<Checkbox checked={props.currentCampaign.fickle} name={"fickle"} onChange={(e)=>{
                                    props.setCurrentCampaign({
                                        ...props.currentCampaign,
                                        fickle: !props.currentCampaign.fickle
                                    })
                                }
                                } />} label="Check Audiences Every Time (fickle)" />
                            </Grid>
                            {!props.isTemplate &&
                                <Grid item xs={12}>

                                    <FormControlLabel control={
                                        <Checkbox checked={props.currentCampaign.repeatable}
                                                  name={"repeatable"}
                                                  onChange={(event)=>{
                                                    props.setCurrentCampaign({
                                                        ...props.currentCampaign,
                                                        repeatable: event.target.checked
                                                    })
                                    }
                                    } />} label="Allow repeat after finish (repeatable)" />
                                </Grid>
                            }
                        </Grid>
                    }


                </Grid></Grid>

        </Loading>
    )
}