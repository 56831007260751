import CampaignForm, {ParametersSet, VersionNumericParameterSet} from "./CampaignForm";
import {useFindCampaignByIdQuery} from "../../core/reducers/campaign";
import dayjs from "dayjs";
import {timeZoneOffset} from "./CampaignSteps/DateStep";
import {convertToDayjs, getEnvName} from "../Utils/CommonUtils";
import {MODE} from "./campaignMode";
import Loading from "../Loading";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useFindTemplateByIdQuery} from "../../core/reducers/templates";

export default function UpdateCampaignForm(props) {
    const timezone = require('dayjs/plugin/timezone');
    const utc = require('dayjs/plugin/utc');

    dayjs.extend(timezone);
    dayjs.extend(utc);
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);

    const ev = getEnvName('env', sourceMode, searchParams);

    const {data: campaign, isError, error, isSuccess, isLoading, status} = useFindCampaignByIdQuery({ev: ev, id: props.campaignId}, {
        skip: props.campaignId === 'null' || (props.template&&props.mode!=MODE.POLY)
    });
    const {data: template, isLoading: isTemplateLoading, isError: isTemplateError, error: errorTemplate} = useFindTemplateByIdQuery({ev: ev, id: props.campaignId}, {
        skip: props.campaignId === 'null' || (props.template&&props.mode==MODE.POLY)
    });

    const sourceCampaign = props.template&&props.mode!=MODE.POLY ? template : campaign
    
    function createNewCampaign() {
        let newCampaign = {};

        if(props.template) {
            newCampaign.campaignId = props.mode === MODE.CLONE ? sourceCampaign.id + ' (Clone)' : sourceCampaign.id;

            if(props.mode == MODE.POLY) {
                newCampaign.schedule = {
                    till: null,
                    delayDays: 0,
                }
            } else {
                newCampaign.schedule = {
                    till: sourceCampaign.schedule.till !== null ? convertToDayjs(sourceCampaign.schedule.till) : null,
                    delayDays: sourceCampaign.schedule.delayDays,
                }
            }

        } else {
            newCampaign.campaignId = props.mode === MODE.CLONE ? sourceCampaign.id + ' (Clone)' : sourceCampaign.id;
        }
        newCampaign.groupId = sourceCampaign.groupId;
        newCampaign.fickle = sourceCampaign.fickle;
        newCampaign.repeatable = sourceCampaign.repeatable;
        newCampaign.priority = sourceCampaign.priority;
        newCampaign.offerType = sourceCampaign.type;
        newCampaign.bundleId = sourceCampaign.bundleId;
        newCampaign.matchmakingType = sourceCampaign.matchmakingType;
        newCampaign.timeZone = timeZoneOffset.displayName;
        newCampaign.from = convertToDayjs(sourceCampaign.distribution.from);
        newCampaign.till = convertToDayjs(sourceCampaign.distribution.till);
        newCampaign.distributionType = sourceCampaign.distribution.type;
        newCampaign.durationHour = sourceCampaign.distribution.durationHour === 0 ? 0 : sourceCampaign.distribution.durationHour;
        newCampaign.audiences = prepareAudiencesForUpdate();
        return newCampaign
    }

    function prepareAudiencesForUpdate() {
        return sourceCampaign.audiences.map((audienceForUpdate, index) => {
            return {
                name: `Audience ${index + 1}`,
                count: index + 1,
                parameters: prepareParametersForUpdate(audienceForUpdate)
            }
        })
    }

    function prepareParametersForUpdate(_audienceForUpdate) {
        let audienceForUpdate = {..._audienceForUpdate}
        let parameters = []

        audienceForUpdate.versionRange.forEach(versionItem => {
            parameters.push(new VersionNumericParameterSet(['VERSION_RANGE'], versionItem.parameter, versionItem.from ? versionItem.from : null, versionItem.to ? versionItem.to : null, versionItem.fromIncluded, versionItem.toIncluded));
        });
        audienceForUpdate.numberRange.forEach(rangeItem => {
            parameters.push(new VersionNumericParameterSet(['NUMBER_RANGE'], rangeItem.parameter, rangeItem.from, rangeItem.to, rangeItem.fromIncluded, rangeItem.toIncluded));
        });

        let available_attributes = ['include','exclude','includeAll','includeAny','excludeAll','excludeAny'];
        available_attributes.forEach((operator) => {
            if(audienceForUpdate.hasOwnProperty(operator) && audienceForUpdate[operator] != null) {
                audienceForUpdate[operator]!=undefined && audienceForUpdate[operator].forEach(includeItem => {
                    let rules = includeItem.parameter === 'country' ? ['INCLUDE', 'EXCLUDE'] : ['INCLUDE'];
                    parameters.push(new ParametersSet(rules, [], null, includeItem.parameter, operator, includeItem.values));
                });
            }
            if(audienceForUpdate[operator] == null) {
                delete audienceForUpdate[operator]
            }

        })
        // audienceForUpdate.include!=undefined && audienceForUpdate.include.forEach(includeItem => {
        //     let rules = includeItem.parameter === 'country' ? ['INCLUDE', 'EXCLUDE'] : ['INCLUDE'];
        //     parameters.push(new ParametersSet(rules, [], null, includeItem.parameter, "include", includeItem.values));
        // });
        // audienceForUpdate.exclude!=undefined && audienceForUpdate.exclude.forEach(excludeItem => {
        //     let rules = excludeItem.parameter === 'country' ? ['INCLUDE', 'EXCLUDE'] : ['INCLUDE'];
        //     parameters.push(new ParametersSet(rules, [], null, excludeItem.parameter, "exclude", excludeItem.values));
        // });
        //'offerTypeInactiveRule','offerInactiveRule'
        if (audienceForUpdate.offerTypeInactiveRule) {
            audienceForUpdate.offerTypeInactiveRule.forEach(offerTypeInactiveRuleItem => {
                parameters.push(new ParametersSet(['TYPE_INACTIVE'], [], null, offerTypeInactiveRuleItem.parameter, "offerTypeInactiveRule", offerTypeInactiveRuleItem.types));

            });
        }
        if (audienceForUpdate.offerInactiveRule) {
            audienceForUpdate.offerInactiveRule.forEach(offerInactiveRuleItem => {
                parameters.push(new ParametersSet(['OFFER_INACTIVE'], [], null, offerInactiveRuleItem.parameter, "offerInactiveRule", offerInactiveRuleItem.offerIds));

            });
        }
        if (audienceForUpdate.offerInProfile) {
            audienceForUpdate.offerInProfile.forEach(offerInProfileItem => {
                parameters.push(new ParametersSet(['OFFER_IN_PROFILE'], [], null, offerInProfileItem.parameter, "offerInProfile", offerInProfileItem.offerId));

            });
        }
        if (audienceForUpdate.offerNotInProfile) {
            audienceForUpdate.offerNotInProfile.forEach(offerNotInProfileItem => {
                parameters.push(new ParametersSet(['OFFER_NOT_IN_PROFILE'], [], null, offerNotInProfileItem.parameter, "offerNotInProfile", offerNotInProfileItem.offerId));

            });
        }
        if (audienceForUpdate.profileOffset) {
            audienceForUpdate.profileOffset.forEach(profileOffset => {
                parameters.push({
                    parameterData: {
                        parameterName:profileOffset.parameter,
                        allowedRules:['PROFILE_OFFSET'],
                    },
                    isMain: null,
                    operator: profileOffset.operator,
                    parameter: profileOffset.parameter,
                    offerId: profileOffset.offerId,
                    offset: profileOffset.offset //"PT" + parseInt() / 3600 +"H"
                });

            });
        }
        _checkIfAllAttributesEmpty(audienceForUpdate, parameters);
        return parameters;
    }

    const _checkIfAllAttributesEmpty = (audienceForUpdate, parameters) => {
        if (Object.values(audienceForUpdate).every(p => p.length===0)) {
            parameters.push(new ParametersSet());
        }
    }

    if (props.template && props.mode == MODE.POLY) {

        return (
            <Loading isLoading={isLoading} isError={isError} error={error}>
                {sourceCampaign!=undefined &&  <CampaignForm
                        template={props.template}
                        mode={props.mode}
                        title={props.campaignId}
                        campaign={createNewCampaign()}
                    />}
            </Loading>
        )
    }
    if (props.template && props.mode != MODE.POLY) {

        return (
            <Loading isLoading={isTemplateLoading} isError={isTemplateError} error={errorTemplate}>
                {sourceCampaign!=undefined &&  <CampaignForm
                        template={props.template}
                        mode={props.mode}
                        title={props.campaignId}
                        campaign={createNewCampaign()}
                    />}
            </Loading>
        )
    }

    return (
        <>
            <Loading isLoading={isLoading} isError={isError} error={error}>

                {campaign != undefined && <CampaignForm
                    template={props.template}
                    mode={props.mode}
                    title={props.campaignId}
                    campaign={createNewCampaign()}
                />}

            </Loading>
        </>
    )
}