import {Button, FormGroup, TextField} from "@mui/material";
import {styled} from "@mui/system";



const StyledTextFieldRight = styled(TextField)({
    width: '40%',
    "& fieldset": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginLeft: 2
    }
});
const StyledTextFieldLeft = styled(TextField)({
    width: '40%',
    "& fieldset": {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        marginRight: 2
    }
});

const StyledButtonRight = styled(Button)({
    minWidth: '10%',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    textTransform: "lowercase"
});

const StyledButtonLeft = styled(Button)({
    minWidth: '10%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    textTransform: "lowercase"
});

export default function StyledFormGroup(props) {

    const isFromIncExcl = () => {
        return props.currentItem.audiences[props.currentAudienceIndex].parameters[props.paramIndex].fromIncl ||
            props.currentItem.audiences[props.currentAudienceIndex].parameters[props.paramIndex].fromIncl === null
    }
    const isToIncExcl = () => {
        return props.currentItem.audiences[props.currentAudienceIndex].parameters[props.paramIndex].toIncl ||
            props.currentItem.audiences[props.currentAudienceIndex].parameters[props.paramIndex].toIncl === null
    }

    const paramFromValue = props.currentItem.audiences[props.currentAudienceIndex].parameters[props.paramIndex].paramFromValue;
    const paramToValue = props.currentItem.audiences[props.currentAudienceIndex].parameters[props.paramIndex].paramToValue;

    function validateVersion(fieldName) {
        let error = false;
        const errors = props.errorFields.filter(e => e.hasOwnProperty('versionError'));
        if (errors.length > 0) {
            errors.forEach(e => {
                error = e.versionError.filter(ve =>
                    ve.audienceIndex === props.currentAudienceIndex &&
                    ve.paramIndex === props.paramIndex &&
                    (ve.fieldName === fieldName || ve.fieldName === 'toAndFromValueError')).length > 0;
            })
        }
        return error;
    }

    function validateNumber(fieldName) {
        let error = false;
        const errors = props.errorFields.filter(e => e.hasOwnProperty('numberError'));
        if (errors.length > 0) {
            errors.forEach(e => {
                error = e.numberError.filter(ve =>
                    ve.audienceIndex === props.currentAudienceIndex &&
                    ve.paramIndex === props.paramIndex &&
                    (ve.fieldName === fieldName || ve.fieldName === 'toAndFromValueError')).length > 0;
            })
        }
        return error;
    }

    return (
        <FormGroup row>
            <StyledButtonLeft variant="contained"
                              disableElevation
                              sx={{fontSize:"24px"}}

                              onClick={() => {
                                  props.handleVersionNumericParameterChange("fromIncl", !isFromIncExcl(), props.paramIndex, props.currentAudienceIndex);
                              }}>
                {isFromIncExcl() ? "[" : "("}
            </StyledButtonLeft>
            <StyledTextFieldLeft
                placeholder='&infin;'
                variant="outlined"
                value={paramFromValue ? paramFromValue : ""}
                error={validateVersion('paramFromValue') || validateNumber('paramFromValue')}
                onChange={(e) =>
                    props.handleVersionNumericParameterChange("paramFromValue", e.target.value, props.paramIndex, props.currentAudienceIndex)}/>
            <StyledTextFieldRight
                placeholder='&infin;'
                variant="outlined"
                value={paramToValue ? paramToValue : ""}
                error={validateVersion('paramToValue') || validateNumber('paramToValue')}
                onChange={(e) =>
                    props.handleVersionNumericParameterChange("paramToValue", e.target.value, props.paramIndex, props.currentAudienceIndex)}
            />
            <StyledButtonRight variant="contained"
                               disableElevation
                               sx={{fontSize:"24px"}}

                               onClick={() => {
                                   props.handleVersionNumericParameterChange("toIncl", !isToIncExcl(), props.paramIndex, props.currentAudienceIndex);
                               }}>
                {isToIncExcl() ? "]" : ")"}
            </StyledButtonRight>
        </FormGroup>
    )
}