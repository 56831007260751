import React, {useEffect, useState, useRef} from 'react';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid, IconButton,
    InputAdornment,
    InputLabel, ListSubheader,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import {useGetAllCampaignsQuery, useTransferMutation} from "../../../core/reducers/campaign";
import Loading from "../../Loading";
import CampaignFrame from "./CampaignFrame";
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {Alert, Autocomplete, LoadingButton} from "@mui/lab";
import {useGetAllEnvironmentsQuery} from "../../../core/reducers/environments";
import SyncPublisherProgress from "./SyncPublisherProgress";
import {useSearchParams} from "react-router-dom";
import {formatInTimeZone} from "date-fns-tz";
import {useDispatch, useSelector} from "react-redux";
import Diff from "../Diff";
import {
    DEFAULT_ENV,
    DEFAULT_ENV2,
    getEnvName,
    getFromStorage,
    GROUP_ADMIN, isAdmin,
    isProdAdmin,
    PROD
} from "../../Utils/CommonUtils";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MultipleSelect from "../MultipleSelect";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router";
import * as PropTypes from "prop-types";
import TransferCheck from "./TransferCheck";
import {CampaignTransferDialog} from "./CampaignTransferDialog";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PublisherCampaigns() {
    const statusItems = ['ACTIVE', 'SUSPENDED', 'HIDDEN', 'DEACTIVATED']
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const [env1, setEnv1] = useState(getEnvName('env', sourceMode, searchParams))
    const [env2, setEnv2] = useState(getEnvName('env2', sourceMode, searchParams))

    const [filterText, setFilterText] = useState("")
    const [filterCampaignType, setFilterCampaignType] = useState(getFromStorage('filterCampaignType') ?
        getFromStorage('filterCampaignType') : '')
    const [filterStatusesSelected, setFilterStatusesSelected] = useState(getFromStorage('filterStatuses') ?
        getFromStorage('filterStatuses').split(',') : ["ACTIVE"])

    const [dateFilter, setDateFilter] = useState({
        from: new Date(useSelector((state) => state.data.DateFilter).from),
        till: new Date(useSelector((state) => state.data.DateFilter).till)
    })

    const [showConfirmTransfer, setShowConfirmTransfer] = useState(false)
    const [confirmErrorsDialog, setConfirmErrorsDialog] = useState(false)
    const [isAllowedSource, setIsAllowedSource] = useState(false)
    const [isProdEnv, setIsProdEnv] = useState(env2 === 'production')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorAfterCheck, setErrorAfterCheck] = useState(false);
    const [transferInProgress, setTransferInProgress] = useState([]);



    const {
        data: environments,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetAllEnvironmentsQuery();


    if(environments !== undefined && env2 != 'production') {
        if(!environments.hasOwnProperty(env1) && env1!=DEFAULT_ENV) setEnv1(DEFAULT_ENV)
        if(!environments.hasOwnProperty(env2) && env2!=DEFAULT_ENV2) setEnv2(DEFAULT_ENV2)
    }

    const getEnvByName = (environments, name) => {
        if(environments == undefined) return [];
        return Object.entries(environments).filter(env => {
            return env[1].name === name
        })
    }

    const userSession = useSelector((state) => state.data.UserSession);


    const isAllowedForEnv = (sourceEnv, targetEnv) => {

        const envObject = getEnvByName(environments, targetEnv);

        if(typeof envObject != "object") return false;

        if (envObject !== undefined && envObject.length>0) {
            if(envObject[0][1].allowedSources.length > 0)
                return envObject[0][1].allowedSources.includes(sourceEnv) ? true : false
        } else {
            return false
        }
        return true
    }

    const prevEnv2 = useRef(env2);
    useEffect(() => {

        if(prevEnv2.current != env2) {
            setSelectedList([])
        }

        prevEnv2.current = env2
    },[env2])


    useEffect(() => {
        console.log(errorAfterCheck)
        setEnv1(getEnvName('env', sourceMode, searchParams));
        setEnv2(getEnvName('env2', sourceMode, searchParams))
        setIsProdEnv(env2 === PROD)
        if (environments && env2 !== 'production') {
            const env = getEnvByName(environments, env2);

            if (env !== undefined && env.length>0) {
                if(env[0][1].allowedSources.length > 0)
                    env[0][1].allowedSources.includes(env1) ? setIsAllowedSource(true) : setIsAllowedSource(false);
                else
                    setIsAllowedSource(true)
            } else {

            }
        } else {
            setIsAllowedSource(true)
        }

    }, [searchParams, environments, env2, env1]);

    const [completedTransferList, setCompletedTransferList] = useState([]);
    const [openResultDialog, setOpenResultDialog] = useState(false);


    const [cachedItems, setCachedItems] = useState({})
    const [selectedList, setSelectedList] = useState([])
    const onSelectItem = (data) => {
        let array = [...selectedList]; // make a separate copy of the array
        let index = array.indexOf(data.item.id)
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(data.item.id)
        }
        setSelectedList(array);

        setCachedItems({...cachedItems, [data.item.id]: data})
    }


    const [
        transfer,
        transferResponse
    ] = useTransferMutation()


    const handleTransfer = async (force) => {
        setShowConfirmTransfer(false)

        debugger
        if(env1=='liveops' && force!==true && selectedList.filter(item => cachedItems[item].item.warnings.length>0).length>0) {
            setConfirmErrorsDialog(true)
            return false
        } else {
            setConfirmErrorsDialog(false)
        }

        setOpenResultDialog(true)
        let newCompletedTransferList = [];
        for (let id in selectedList) {
            let campaign = selectedList[id]
            newCompletedTransferList.push({campaign: campaign})
            let data = {
                environment: env2,
                source: env1,
                campaign: campaign,
                env: env2 //selectedList.join(",")
            }
            const resp = await transfer(data)

            console.log('Try transfer ' + campaign + ' from ' + env1 + ' to ' + env2 )
            console.log('Response ', resp )
            if (
                (resp.hasOwnProperty('data') && resp.data.hasOwnProperty('transfer_id'))
            ) {
                newCompletedTransferList.filter(i => i.campaign === campaign)[0].transferred = true;
                newCompletedTransferList.filter(i => i.campaign === campaign)[0].transferId = resp.data.transfer_id;
                setCompletedTransferList(newCompletedTransferList);
                console.log(`Transfer successful for campaign: ${selectedList[id]}`);
            } else if(resp.hasOwnProperty('error')) {
                newCompletedTransferList.filter(i => i.campaign === campaign)[0].transferred = false;
                let error_text = '';

                if(resp.error.hasOwnProperty("error")) error_text = resp.error.error
                else if(resp.error.hasOwnProperty("data") && resp.error.data.hasOwnProperty("text")) error_text = resp.error.data.text.message
                else if(resp.error.hasOwnProperty("data") && resp.error.data.hasOwnProperty("message")) error_text = resp.error.data.message
                else error_text = JSON.stringify(resp.error)

                if(resp.error.hasOwnProperty("status")) error_text = 'Error ' + resp.error.status + ': ' + error_text

                newCompletedTransferList.filter(i => i.campaign === campaign)[0].error = error_text;
                setCompletedTransferList(newCompletedTransferList);
                console.log('Error during transfer: ', resp)
            }
            else {
                newCompletedTransferList.filter(i => i.campaign === campaign)[0].transferred = false;
                newCompletedTransferList.filter(i => i.campaign === campaign)[0].error = 'unknown error';

                setCompletedTransferList(newCompletedTransferList);
                console.log('Error during transfer: ', transferResponse)
            }
        }
        // setSelectedList([])
    }

    const handleFilterChange = (event) => {
        setFilterText(event.target.value)
    }

    const handeFilterCampaignType = (event) => {
        setFilterCampaignType(event.target.value)
        localStorage.setItem('filterCampaignType', event.target.value)
    }

    const handleChangeFilterStatus = (statuses) => {
        setFilterStatusesSelected(statuses);
        localStorage.setItem('filterStatuses', statuses)

    }


    const formatdate = (timestamp) => {
        return formatInTimeZone(new Date(timestamp * 1000), 'America/New_York', 'dd.MM.yyyy HH:mm')
    }

    const chooseTransferDialogTitle = () => {

        if (errorAfterCheck || completedTransferList.some(i => (i.hasOwnProperty('transferred') && !i.transferred))) {
            return 'Some campaigns failed to be transferred'
        }
        if (completedTransferList.length === 0) {
            return 'Transfer starts soon...'
        }
        if (completedTransferList.every(i => i.transferred)
            && completedTransferList.length === transferInProgress.length
            && transferInProgress.every(i=>i===false)) {

            return 'Transferred successfully:'
        }
        return 'In progress...'
    }

    const setEnv1Name = (evn1Name) => {
        setEnv1(evn1Name)
        const env2 = getEnvName('env2', sourceMode, searchParams);
        setSearchParams({env: evn1Name, env2: env2});
        dispatch({
            type: 'EV1_NAME', payload: {
                data: {
                    env1Name: evn1Name
                }
            }
        });
    }

    const setEnv2Name = (evn2Name) => {
        setEnv2(evn2Name)
        const env1 = getEnvName('env', sourceMode, searchParams);
        setSearchParams({env: env1, env2: evn2Name});
        dispatch({
            type: 'EV2_NAME', payload: {
                data: {
                    env2Name: evn2Name
                }
            }
        });
    }
    const swapEnv = () => {
        const env = getEnvName('env', sourceMode, searchParams);
        console.log("env")
        console.log(env)
        const env2Name = getEnvName('env2', sourceMode, searchParams);
        setEnv1Name(env2Name);
        setEnv2Name(env);
        setSearchParams({env: env2Name, env2: env});

        setSelectedList([])
    }


    const createDisabled = () => {
        if(environments===undefined ) return false

        const envObj = getEnvByName(environments, env1)
        if(envObj !== undefined && envObj.length > 0) {
            const disabled = !envObj[0][1].hasCreator
            return disabled
        }

        return false
     }
    const createDisabledEnv2 = () => {
        if(environments===undefined ) return false

        const envObj = getEnvByName(environments, env2)
        if(envObj !== undefined && envObj.length > 0) {
            const disabled = !envObj[0][1].hasCreator
            return disabled
        }

        return false
     }


    const sortByName = (a, b) => {
        return a[1].name.localeCompare(b[1].name);
    }

    const onCloseTransferDialog = () => {
        setSelectedList([])
        setErrorAfterCheck(false);
        setTransferInProgress([]);
        setOpenResultDialog(false);
        setCompletedTransferList([])
    }

    const handleCreateCampaingClick = () => {
        navigate(`/creator/campaigns?env=${env1}`)
    }

    const getFromCompletedList = (selectedItem) => {
        return completedTransferList.filter(item => item.campaign===selectedItem)[0]
    }

    const transferTooltipMsg = isProdEnv ? 'You are not allowed to transfer to prod' : `'${env1}' isn't contained in allowed sources of '${env2}'`;



    const sortedEnvirnoments = environments == undefined ? [] : Object.entries(environments).sort((a, b) => {
        if (a[1].reserved && !b[1].reserved) return -1;
        if (!a[1].reserved && b[1].reserved) return 1;
        return (b[1].reservedPriority - a[1].reservedPriority);
    })


    const hasLiveopsErrors = () => {}

    const getWarningsAlert = (item) => {

        if(cachedItems[item].item.warnings.length == 0) return ''
        return <Alert  severity={"warning"}>{cachedItems[item].item.warnings.map(_warning => (<div>- {_warning}</div>))}</Alert>

    }

    return <Loading isError={isError} isLoading={isLoading} isSuccess={isSuccess} error={error}>

        <Box>
            <Box
                className={"filters-sticky"}
                sx={{ display: 'flex' }}
            >

                <Box sx={{ pr:1, width:"200px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="super-type-id">Super Type</InputLabel>
                        <Select
                            labelId="super-type"
                            id="super-type-select"
                            value={filterCampaignType}
                            label="Super Type"
                            onChange={handeFilterCampaignType}
                        >
                            <MenuItem value="All types">All</MenuItem>
                            <MenuItem value="LoadableContent">LC</MenuItem>
                            <MenuItem value="ExtendedContent">EC</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{  pr:1, width:"200px" }}>
                    <MultipleSelect label={"Status"}
                                    sx={{p:0}}
                                    labelId={"Statuses"}
                                    items={statusItems}
                                    defaultSelectedItems={filterStatusesSelected}
                                    setSelectedItems={handleChangeFilterStatus}/>
                </Box>
                <Box sx={{ flexGrow: 1, pr:1,  }}>
                    <TextField
                        sx={{width: '100%'}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        placeholder="campaign name"
                        onChange={(event) => handleFilterChange(event)}></TextField>
                </Box>
                <Box sx={{  pr:1, width:"170px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="from"
                            value={dateFilter.from}
                            format={"y-M-d"}
                            onChange={(newValue) => {
                                setDateFilter({
                                    ...dateFilter,
                                    from: newValue
                                });
                                dispatch({
                                    type: 'DATE_FILTER_FROM', payload: {
                                        data: {
                                            from: newValue
                                        }
                                    }
                                });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{  pr:1, width:"170px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="till"
                            format={"y-M-d"}
                            value={dateFilter.till}
                            onChange={(newValue) => {
                                setDateFilter({
                                    ...dateFilter,
                                    till: newValue
                                });
                                dispatch({
                                    type: 'DATE_FILTER_TILL', payload: {
                                        data: {
                                            till: newValue
                                        }
                                    }
                                });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>



            <Box className="fix-top-transfer" sx={{ display: 'flex', flexWrap:'nowrap' }} spacing={0}
            >

                {!isProdEnv &&<Box className="env-btn" alignSelf="center">
                    <Tooltip
                        disableHoverListener={!createDisabled()}
                        title={`'${env1}' disabled for creator`}>
                        <div>
                            <Button
                                size={"small"}
                                fullWidth
                                disabled={createDisabled()}
                                variant="contained"
                                color={"success"}
                                onClick={handleCreateCampaingClick}>
                                <AddIcon/>
                            </Button>
                        </div>
                    </Tooltip>
                </Box>}

                <Box sx={{ pr:0, pl:1}} className="env-select" alignSelf="center">

                    {env2 === PROD && <div style={{textAlign:"right"}}><strong style={{width:"100px",display:"inline-block"}}>{env1}</strong></div>}
                    {env2 !== PROD && <FormControl size="small" fullWidth>

                        {environments !== undefined &&

                            <Autocomplete
                                id="grouped-demo"
                                size={"small"}
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}

                                options={sortedEnvirnoments}
                                groupBy={(option) => (option[1].reserved ? 'Reserved' : 'Custom')}
                                getOptionLabel={(option) => option[0]}
                                renderInput={(params) => <TextField {...params} label=""/>}
                                renderGroup={(params) => (
                                    <li key={params.key} >
                                        <ListSubheader>{params.group}</ListSubheader>
                                        {params.children}
                                    </li>
                                )}
                                onChange={(event, value) => {
                                    debugger
                                    if(value != null) {
                                        setSelectedList([])
                                        setEnv1Name(value[0])
                                    }
                                }}
                                value={sortedEnvirnoments.filter(item => item[0] == env1)[0]}

                                componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                disableClearable={true}
                                PopperProps={{
                                    style: {
                                        minWidth: '1000px'
                                    },
                                    modifiers: [
                                        {
                                            name: 'computeStyles',
                                            options: {
                                                adaptive: false, // Disable adaptive to control width manually
                                            },
                                        },
                                    ],
                                }}
                            />

                        }

                    </FormControl>}

                </Box>
                <Box sx={{ pr:1, pl:1, flex:"0 0 180px" }} alignSelf="center" textAlign="center"
                >

                        {(isAllowedSource && !(isProdEnv&&!isAdmin(userSession))) ?
                                <Button
                                sx={{mt: 0}}

                                variant="contained" onClick={() => setShowConfirmTransfer(true) }
                                disabled={isAllowedSource === false || selectedList.length === 0}>
                                Transfer [{selectedList.length}]
                            </Button> :
                            <Tooltip sx={{mt: 0}}
                                     title={transferTooltipMsg}>
                                <Button variant="contained"
                                        color="secondary"
                                        fullWidth
                                >

                                    Transfer [{selectedList.length}]
                                </Button>
                            </Tooltip>
                        }

                        {env2=='production' && <SyncPublisherProgress/>}

                </Box>
                <Box sx={{ pr:1, pl:0 }} className="env-select" alignSelf="center">
                    {env2 === PROD && <div style={{textAlign:"left"}}><strong style={{width:"100px",display:"inline-block"}}>{env2}</strong></div>}
                    {env2 !== PROD && <FormControl size="small" fullWidth>
                        {environments !== undefined && <Autocomplete
                            id="grouped-demo"
                            size={"small"}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}

                            options={sortedEnvirnoments}
                            groupBy={(option) => (option[1].reserved ? 'Reserved' : 'Custom')}
                            getOptionLabel={(option) => option[0]}
                            renderInput={(params) => <TextField {...params} label=""/>}
                            renderGroup={(params) => (
                                <li key={params.key} >
                                    <ListSubheader>{params.group}</ListSubheader>
                                    {params.children}
                                </li>
                            )}
                            onChange={(event, value) => {
                                debugger
                                if(value != null) {
                                    setSelectedList([])
                                    setEnv2Name(value[0])

                                }
                            }}
                            value={sortedEnvirnoments.filter(item => item[0] == env2)[0]}

                            componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                            disableClearable={true}
                            PopperProps={{
                                style: {
                                    minWidth: '1000px'
                                },
                                modifiers: [
                                    {
                                        name: 'computeStyles',
                                        options: {
                                            adaptive: false, // Disable adaptive to control width manually
                                        },
                                    },
                                ],
                            }}
                        />}

                    </FormControl>}

                </Box>
                {
                    !isProdEnv &&
                    <Box className="env-btn" alignSelf="center">
                            <Tooltip
                                disableHoverListener={isAllowedForEnv(env2, env1)}
                                sx={{mt: 0}}
                                     title={`'${env1}' isn't contained in allowed sources of '${env2}'`}>
                                <div>
                                    <Button
                                        fullWidth
                                        size={"small"}
                                        variant="contained"
                                        color={"success"}
                                        disabled={!isAllowedForEnv(env2, env1)}
                                        onClick={() => swapEnv()}>
                                    <SyncAltIcon/>
                                </Button>
                                </div>
                            </Tooltip>
                    </Box>
                }

            </Box>

            <Grid container>
                <Grid item sm={12} md={6} sx={{pr:1}}>
                    <CampaignFrame
                        env={env1}
                        onSelectItem={onSelectItem}
                        selectedList={selectedList}
                        filterCampaignType={filterCampaignType === "All types" ? "" : filterCampaignType}
                        filterStatuses={filterStatusesSelected}
                        createDisabled={createDisabled()}
                        dateFilter={dateFilter}
                        filterText={filterText}/>


                </Grid>

                <Grid sm={12} item md={6} sx={{pl: 1}}>
                    <CampaignFrame env={env2}
                                   createDisabled={createDisabledEnv2()}
                                   filterText={filterText}
                                   filterCampaignType={filterCampaignType === "All types" ? "" : filterCampaignType}
                                   filterStatuses={filterStatusesSelected}
                                   dateFilter={dateFilter}
                                   rightPanel={true}
                    />
                </Grid>

            </Grid>
        </Box>

        {showConfirmTransfer && <Dialog
            open={showConfirmTransfer}
            onClose={() => {
                setShowConfirmTransfer(!showConfirmTransfer)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                Transfer from <strong>{env1}</strong> to <strong>{env2}</strong>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {!isProdAdmin(env2, userSession) ? <Alert severity={"error"}>Недостаточно прав</Alert> :
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        From
                                    </TableCell>
                                    <TableCell>
                                        Till
                                    </TableCell>
                                    <TableCell>
                                        Audience
                                    </TableCell>
                                    <TableCell>
                                        Going to update bundles:
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    New bundle*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {selectedList.map(item => (
                                    <Diff from={env1} to={env2} item={item} cachedItems={cachedItems}></Diff>

                                ))}
                            </TableBody>
                        </Table>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => setShowConfirmTransfer(false)}>Cancel</Button>
                {isProdAdmin(env2, userSession) &&
                    <LoadingButton variant="contained" onClick={handleTransfer} loading={transferResponse.isLoading}
                                   disabled={selectedList.length == 0}>
                        Transfer
                    </LoadingButton>}
            </DialogActions>
        </Dialog>
        }

        {confirmErrorsDialog&&<Dialog
            open={confirmErrorsDialog}
            onClose={() => {
                setConfirmErrorsDialog(!confirmErrorsDialog)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                You are going to transfer campaigns with warnings from <b>{env1}</b> to <b>{env2}</b>. Are you sure?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {!isProdAdmin(env2, userSession) ? <Alert severity={"error"}>Недостаточно прав</Alert> :
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Campaign name
                                    </TableCell>
                                    <TableCell>
                                        Warnings
                                    </TableCell>

                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {selectedList.filter(item => cachedItems[item].item.warnings.length > 0).map(item => (
                                    <TableRow selected={true}>
                                        <TableCell>{item}</TableCell>
                                        <TableCell>{getWarningsAlert(item)}</TableCell>
                                    </TableRow>

                                ))}
                                {selectedList.filter(item => cachedItems[item].item.warnings.length == 0).map(item => (
                                    <TableRow>
                                        <TableCell>{item}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() =>setConfirmErrorsDialog(!confirmErrorsDialog)}>Cancel</Button>
                {isProdAdmin(env2, userSession) &&
                    <LoadingButton variant="contained" onClick={() => handleTransfer(true)} loading={transferResponse.isLoading}
                                   disabled={selectedList.length == 0}>
                        Yes, i am
                    </LoadingButton>}
            </DialogActions>
        </Dialog>}

        {openResultDialog&&<CampaignTransferDialog
            openResultDialog={openResultDialog}
            onCloseTransferDialog={onCloseTransferDialog}
            chooseTransferDialogTitle={chooseTransferDialogTitle}
            transferResponse={transferResponse}
            completedTransferList={completedTransferList}
            getFromCompletedList={getFromCompletedList}
            env2={env2}
            selectedList={selectedList}
            dateFilter={dateFilter}
            errorAfterCheck={errorAfterCheck}
            transferInProgress={transferInProgress}
            setTransferInProgress={setTransferInProgress}
            setErrorAfterCheck={setErrorAfterCheck}
        />}
    </Loading>;
}