import api from "../api/base-api"

const variantApi = api.injectEndpoints({
        endpoints: (build) => ({

            variantAll: build.query({
                query: ({type,env}) => `/admin/variant/publisher/context?environment=${env}&type=${type}`,
                providesTags: (result, error, arg) => {
                    return ['variantsAll']
                },
                keepUnusedDataFor: 0
            }),

            variantBase: build.query({
                query: ({variant, type,env}) => `/admin/variant/${variant}/search?environment=${env}&type=${type}`,
                providesTags: (result, error, arg) => {
                    return ['variant','variantTest','variantBase']
                },
                keepUnusedDataFor: 0
            }),

            deleteVariantBase: build.mutation({
                query: ({variant, id, env}) => {
                    return {
                        url: `/admin/variant/${variant}/delete?environment=${env}&id=${id}`,
                        method: "POST",
                    }
                },
                invalidatesTags: ['variant','variantsAll']

            }),
            createVariantBase: build.mutation({
                query: (data) => {
                    return {
                        url: `/admin/variant/base/save?environment=${data.env}`,
                        method: "POST",
                        body: data.body,
                        env: data.env
                    }
                },
                invalidatesTags: ['variant','variantsAll']
            }),

            variantTest: build.query({
                query: (type) => `/admin/variant/test/search?environment=liveops&type=${type}`,
                providesTags: (result, error, arg) => {
                    return ['variantTest']
                },
                keepUnusedDataFor: 0
            }),

            deleteVariantTest: build.mutation({
                query: (id) => {
                    return {
                        url: `/admin/variant/test/delete?environment=liveops&id=${id}`,
                        method: "POST",
                    }
                },
                invalidatesTags: ['variantTest','variantsAll']

            }),
            buildBundle: build.mutation({
                query: (data) => {
                    return {
                        url: `/admin/variant/publisher/build/bunlde?testVariantId=${data.id}&environment=${data.env}`,
                        method: "POST",
                    }
                },
                invalidatesTags: ['variantTest','variantsAll']
            }),
            buildQr: build.mutation({
                query: (data) => {
                    return {
                        url: `/admin/variant/publisher/build/qr?testVariantId=${data.id}&environment=${data.env}`,
                        method: "POST",
                    }
                },
                invalidatesTags: ['variantTest','variantsAll']
            }),
            createVariantTest: build.mutation({
                query: (data) => {
                    return {
                        url: `/admin/variant/test/save?environment=${data.env}`,
                        method: "POST",
                        body: data.body,
                        env: data.env
                    }
                },
                invalidatesTags: ['variantTest','variantsAll']
            }),

        }),
    }
)

export const {

    useVariantAllQuery,
    useVariantBaseQuery,
    useCreateVariantBaseMutation,
    useDeleteVariantBaseMutation,

    useVariantTestQuery,
    useCreateVariantTestMutation,
    useDeleteVariantTestMutation,
    useBuildBundleMutation,
    useBuildQrMutation,
} = variantApi;