import api from "../api/base-api"
import {AB_TESTS} from "../../components/Utils/CommonUtils";

const abtestApi = api.injectEndpoints({
    endpoints: (build) => ({
        searchAbTests: build.query({
            query: (data) => {
                return {
                    url: `/admin/abtest/search?environment=${data.env}&id=${data.id}`,
                    env: data.env
                }
            },
            extraOptions : {
                type: AB_TESTS
            }
        }),
        getAbtestParameters: build.query({
            query: (data) => {
                return {
                    url: `/admin/abtest/parameters?environment=${data.env}`,
                    env: data.env
                }
            },
            providesTags: ['parameters'],
            transformResponse: (response) => {
                return response.sort((a, b) => a.parameterName.localeCompare(b.parameterName)).sort((a, b) => (b.isMain - a.isMain));
            },
            extraOptions : {
                type: AB_TESTS
            }
        }),
        getAllABTests: build.query({
            query: (data) => {
                let query = '';
                if (data.hasOwnProperty('from')) {
                    query = `&from=${data.from}&till=${data.till}`
                }
                return {
                    url: `/admin/publisher/context?environment=${data.env}` + query,
                    env: data.env
                }
            },
            transformResponse: (response, meta, arg) => {
                let abtestList = response.abTests.map((abtest, index) =>
                    Object.assign({}, abtest, {type: "AB Tests"})
                )
                return  Object.assign({},  {
                    types: [{},],
                    abtests: abtestList
                });
            },
            providesTags: ['abtestsList'],

            extraOptions : {
                type: AB_TESTS
            }
        }),
        selectAbTestById: build.query({
            query: (data) => {
                return {
                    url: `/admin/abtest/select?environment=${data.env}&id=${data.id}`,
                    env: data.env
                }
            },
            extraOptions : {
                type: AB_TESTS
            },
            providesTags: ['getAbTestById']
        }),
        checkAbTestById: build.mutation({
            query: (data) => {
                return {
                    url: `/admin/abtest/select?environment=${data.env}&id=${data.id}`,
                    env: data.env,
                    method: "GET",
                }
            },
            extraOptions : {
                type: AB_TESTS
            },
            providesTags: ['getAbTestById']
        }),
        setAbtestStatus: build.mutation({
            query: (data) => {
                const {environment, status, id, cohort} = data

                let url = `/admin/abtest/status?environment=${environment}&id=${id}&status=${status}`
                if(cohort != undefined) url += `&cohort=${cohort}`
                return {
                    url: url,
                    method: "POST",
                    body: data,
                    env: data.environment
                }
            },
            extraOptions : {
                type: AB_TESTS
            }
        }),
        transferAbtest: build.mutation({
            query: (data) => {
                const {environment, source, abtest, env} = data

                return {
                    url: `/admin/transfer/transfer?environment=${environment}&source=${source}&campaign=${abtest}`,
                    method: "POST",
                    body: data,
                    env: env
                }
            },
            extraOptions : {
                type: AB_TESTS
            },
            invalidatesTags: ['abtestsList']

        }),
        saveAbTest: build.mutation({
            query: ({body, env, original}) => {
                let url = `/admin/abtest/save?environment=${env}`;

                // Если параметр original существует, добавляем его в URL
                if (original) {
                    url += `&original=${original}`;
                }

                return {
                    url: url,
                    method: "POST",
                    body: body,
                    env: env
                };
            },
            extraOptions: {
                type: AB_TESTS
            },
            invalidatesTags: ['abtestsList', 'getAbTestById']
        })

    }),
});

export const {
    useSearchAbTestsQuery,
    useGetAbtestParametersQuery,
    useSelectAbTestByIdQuery,
    useCheckAbTestByIdMutation,
    useGetAllABTestsQuery,
    useSetAbtestStatusMutation,
    useCheckCampaignTransferStatusQuery,
    useTransferAbtestMutation,
    useSaveAbTestMutation,
} = abtestApi;