import {
    Box,
    Button, Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
    useGetBundleHistoryQuery, useGetHistoryQuery,
    useGetPlatformsInfoQuery, useRollbackAllBundleMutation,
    useRollbackBundleMutation
} from "../../core/reducers/campaign";
import Loading from "../Loading";
import clipboardCopy from "clipboard-copy";
import dayjs from "dayjs";
import {isAdmin, isProdAdmin, isProdEnv, LIVEOPS_ENV, PROD} from "../Utils/CommonUtils";
import {useSelector} from "react-redux";

import {SnackBarContext} from "../../core/context/snackBarProvider";
import {Alert, LoadingButton} from "@mui/lab";
import {formatdate} from "../../constants";
import RollbackResponseDialog from "./RollbackResponseDialog";
import {useState} from "react";
import AsTable from "../AsTable";
import ReactJsonViewCompare from 'react-json-view-compare';
import ReactJson from "react-json-view";

export default function HistoryDialog({item, open, setOpen, env}) {

    const {data, isLoading, isError, error} = useGetHistoryQuery({environment: env, event_id: item.id}, {skip: !open});

    const [openDiff, setOpenDiff] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleCloseDiff = () => {
        setOpenDiff(false);
        setOpenInfo(false);
        setSelectedRow(null);
    };
    return (
        <><Dialog
            maxWidth={'xl'}
            onClose={() => setOpen(false)} open={open}>
            <DialogTitle></DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>


                <Loading isLoading={isLoading} isError={isError} error={error}>
                    {data != undefined ?
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Username</TableCell>
                                    <TableCell>AdminAction</TableCell>
                                    <TableCell>Created At</TableCell>

                                    <TableCell>ID</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Diff</TableCell>
                                    <TableCell>Info</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.logs.map(item => (
                                    <TableRow>
                                        <TableCell>{item.userName} ({item.userEmail})</TableCell>
                                        <TableCell>{item.adminAction}</TableCell>
                                        <TableCell>{dayjs(item.creationDateSeconds * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')}</TableCell>

                                        <TableCell>{item.eventId}</TableCell>
                                        <TableCell>{item.eventType}</TableCell>
                                        <TableCell><Button variant={"outlined"} onClick={() => {
                                            setSelectedRow({
                                                oldData: item.oldData,
                                                newData: item.newData,
                                                info: item.info,
                                            });
                                            setOpenDiff(true);
                                        }}>Show Diff</Button></TableCell>
                                        <TableCell>{item.info!=null&&Object.keys(item.info).length>0&&<Button variant={"outlined"} onClick={() => {
                                            setSelectedRow({
                                                oldData: item.oldData,
                                                newData: item.newData,
                                                info: item.info,
                                            });
                                            setOpenDiff(true);
                                            setOpenInfo(true);
                                        }}>Show Info</Button>}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        : <Alert severity={'info'}>no data for {item.id}</Alert>
                    }
                </Loading>
            </DialogContent>
            <DialogActions>

                <Button variant="contained"
                        onClick={()=>{setOpen(false)}}>
                    Close
                </Button>

            </DialogActions>
        </Dialog>

            {openDiff&&<Dialog open={openDiff} onClose={handleCloseDiff} maxWidth="md" fullWidth>
                <DialogTitle>JSON Diff</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDiff}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    {!openInfo&&<ReactJsonViewCompare newData={selectedRow.newData} oldData={selectedRow.oldData} />}
                    {openInfo&&<ReactJson
                        theme={'bright:inverted'}
                        src={selectedRow.info}
                        iconStyle={'square'}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                    />}
                </DialogContent>
                <DialogActions>


                    <Button variant="outlined"
                            onClick={handleCloseDiff}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>}
        </>
    );
}