import {Typography} from "@mui/material";
import Loading from "../Loading";
import {VariantItem} from "./VariantItem";
import {useVariantAllQuery, useVariantBaseQuery, useVariantTestQuery} from "../../core/reducers/variant";

export const TestVariants = ({filterText, filterType, env}) => {


    const {
        data: variantsTest,
        isLoading: isLoadingTest,
        isError: isErrorTest,
        error: errorTest
    } = useVariantAllQuery({type:filterType,env:env},{pollingInterval: 5000})

    // const {
    //     data: data,
    //     isLoading: isLoadingTest,
    //     isError: isErrorTest,
    //     error: errorTest
    // } = useVariantTestQuery(filterType,{pollingInterval: 5000})
    // const variantsTest = {
    //     testVariants: data != undefined ? data : []
    // }

    return <div className="campaign-group">

        <div className="campaign-type-title">
            <Typography sx={{p:0.5}}><strong>Test variants</strong></Typography>

        </div>

        <div className="campaigns-list variants">
            <Loading isLoading={isLoadingTest} isError={isErrorTest} error={errorTest}>
                {variantsTest!=undefined && variantsTest.testVariants.filter(item => filterText==''||(item.id!=null&&item.id.indexOf(filterText)>=0)).map((item) => (<VariantItem env={env} variant={"test"} item={item} />))}
            </Loading>

        </div>
    </div>
}