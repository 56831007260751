import Loading from "../Loading";
import CampaignForm, {ParametersSet, VersionNumericParameterSet} from "../Campaign/CampaignForm";
import {MODE} from "../Campaign/campaignMode";
import dayjs from "dayjs";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {convertToDayjs, getEnvName, saveHistory} from "../Utils/CommonUtils";
import {useSelectAbTestByIdQuery} from "../../core/reducers/abtest";
import AbTestForm from "./AbTestForm";
import {useEffect} from "react";
import {SEARCH_TYPES} from "../../core/reducers/typeFilter";

export default function UpdateAbTestForm({abTestId, mode}) {

    const timezone = require('dayjs/plugin/timezone');
    const utc = require('dayjs/plugin/utc');

    dayjs.extend(timezone);
    dayjs.extend(utc);
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const envName = getEnvName('env', sourceMode, searchParams);
    const {error, status, data: abTest, isLoading, isError} = useSelectAbTestByIdQuery({
        env: envName,
        id: abTestId
    }, {skip: !abTestId});


    useEffect(() => {
        if (mode === MODE.UPDATE && status === 'fulfilled') {
            saveHistory(abTest.id, getEnvName('env', sourceMode, searchParams), SEARCH_TYPES.AB_TESTS)
        }
    }, [status])

    const createNewAbTest = () => {
        let newAbTest = {};
        newAbTest.abTestId = mode === MODE.CLONE ? abTest.id + ' (Clone)' : abTest.id;
        newAbTest.groupId = abTest.groupId;
        newAbTest.fickle = abTest.fickle;
        newAbTest.priority = abTest.priority;
        newAbTest.reassignCohort = abTest.reassignCohort;
        newAbTest.from = convertToDayjs(abTest.distribution.from);
        newAbTest.till = convertToDayjs(abTest.distribution.till);
        newAbTest.audiences = prepareAudiencesForUpdate();
        newAbTest.cohorts = prepareCohortsForUpdate();
        debugger
        newAbTest.metaData = abTest.metaData;
        return newAbTest;
    }

    const prepareParametersForUpdate = (_audienceForUpdate) => {
        let audienceForUpdate = {..._audienceForUpdate}
        let parameters = []
        audienceForUpdate.versionRange.forEach(versionItem => {
            parameters.push(new VersionNumericParameterSet(['VERSION_RANGE'], versionItem.parameter, versionItem.from ? versionItem.from : null, versionItem.to ? versionItem.to : null, versionItem.fromIncluded, versionItem.toIncluded));
        });
        audienceForUpdate.numberRange.forEach(rangeItem => {
            parameters.push(new VersionNumericParameterSet(['NUMBER_RANGE'], rangeItem.parameter, rangeItem.from, rangeItem.to, rangeItem.fromIncluded, rangeItem.toIncluded));
        });

        let available_attributes = ['include','exclude','includeAll','includeAny','excludeAll','excludeAny'];
        available_attributes.forEach((operator) => {
            if (audienceForUpdate.hasOwnProperty(operator) && audienceForUpdate[operator] != null) {
                audienceForUpdate[operator].forEach(includeItem => {
                    let rules = includeItem.parameter === 'country' ? ['INCLUDE', 'EXCLUDE'] : ['INCLUDE'];
                    parameters.push(new ParametersSet(rules, [], null, includeItem.parameter, operator, includeItem.values));
                })


            }
        })
        //
        // audienceForUpdate.include.forEach(includeItem => {
        //     let rules = includeItem.parameter === 'country' ? ['INCLUDE', 'EXCLUDE'] : ['INCLUDE'];
        //     parameters.push(new ParametersSet(rules, [], null, includeItem.parameter, "include", includeItem.values));
        // });
        // audienceForUpdate.exclude.forEach(excludeItem => {
        //     let rules = excludeItem.parameter === 'country' ? ['INCLUDE', 'EXCLUDE'] : ['INCLUDE'];
        //     parameters.push(new ParametersSet(rules, [], null, excludeItem.parameter, "exclude", excludeItem.values));
        // });
        if (audienceForUpdate.offerTypeInactiveRule) {
            audienceForUpdate.offerTypeInactiveRule.forEach(offerTypeInactiveRuleItem => {
                parameters.push(new ParametersSet(['TYPE_INACTIVE'], [], null, offerTypeInactiveRuleItem.parameter, "offerTypeInactiveRule", offerTypeInactiveRuleItem.types));

            });
        }
        if (audienceForUpdate.offerInactiveRule) {
            audienceForUpdate.offerInactiveRule.forEach(offerInactiveRuleItem => {
                parameters.push(new ParametersSet(['OFFER_INACTIVE'], [], null, offerInactiveRuleItem.parameter, "offerInactiveRule", offerInactiveRuleItem.offerIds));

            });
        }
        if (audienceForUpdate.offerInProfile) {
            audienceForUpdate.offerInProfile.forEach(offerInProfileItem => {
                parameters.push(new ParametersSet(['OFFER_IN_PROFILE'], [], null, offerInProfileItem.parameter, "offerInProfile", offerInProfileItem.offerId));

            });
        }
        if (audienceForUpdate.offerNotInProfile) {
            audienceForUpdate.offerNotInProfile.forEach(offerNotInProfileItem => {
                parameters.push(new ParametersSet(['OFFER_NOT_IN_PROFILE'], [], null, offerNotInProfileItem.parameter, "offerNotInProfile", offerNotInProfileItem.offerId));

            });
        }
        if (audienceForUpdate.offerInactiveRule) {
            audienceForUpdate.offerInactiveRule.forEach(item => {
                parameters.push(new ParametersSet(['TYPE_INACTIVE'], [], null, item.parameter, "offerInactiveRule", item.offerId));

            });
        }
        if (audienceForUpdate.offerTypeInactiveRule) {
            audienceForUpdate.offerTypeInactiveRule.forEach(item => {
                parameters.push(new ParametersSet(['OFFER_INACTIVE'], [], null, item.parameter, "offerTypeInactiveRule", item.offerId));

            });
        }
        if (audienceForUpdate.profileOffset) {
            audienceForUpdate.profileOffset.forEach(profileOffset => {
                parameters.push({
                    parameterData: {
                        parameterName:profileOffset.parameter,
                        allowedRules:['PROFILE_OFFSET'],
                    },
                    isMain: null,
                    operator: profileOffset.operator,
                    parameter: profileOffset.parameter,
                    offerId: profileOffset.offerId,
                    offset: profileOffset.offset //"PT" + profileOffset.offset / 3600 +"H"
                });

            });
        }
        return parameters;
    }

    const prepareAudiencesForUpdate = () => {
        return abTest.audiences.map((audienceForUpdate, index) => {
            return {
                name: `Audience ${index + 1}`,
                count: index + 1,
                parameters: prepareParametersForUpdate(audienceForUpdate)
            }
        })
    }

    const transformUserGroups = (users) => {
        if (users) {
            if (typeof users === 'string') {
                return [users];
            }
            return users;
        } else {
            return [];
        }
    }

    const prepareCohortsForUpdate = () => {
        let cohorts = [];
        Object.keys(abTest.cohorts).forEach(key => {
            cohorts.push({
                id: abTest.cohorts[key].id,
                limit: abTest.cohorts[key].limit,
                weight: abTest.cohorts[key].weight,
                active: abTest.cohorts[key].active,
                parameters: JSON.stringify(abTest.cohorts[key].parameters),
                size: abTest.cohorts[key].size,
                userGroup: transformUserGroups(abTest.cohorts[key].userGroups),
                creationDate: abTest.cohorts[key].creationDate
            });
        });
        return cohorts;
    }

    if (mode === MODE.CLONE) {

        return (
            <Loading isLoading={isLoading} isError={isError} error={error}>
                {abTest !== undefined &&  <AbTestForm
                    mode={MODE.CLONE}
                    title={abTestId}
                    abTest={createNewAbTest()}
                />}
            </Loading>
        )
    }

    return (
        <>
            <Loading isLoading={isLoading} isError={isError} error={error}>

                {abTest !== undefined && <AbTestForm
                    mode={MODE.UPDATE}
                    title={abTestId}
                    abTest={createNewAbTest()}
                />}

            </Loading>
        </>
    )
}