import {
    getCurrentParam,
    isVersionNumericParameter,
    ParametersSet,
    VersionNumericParameterSet
} from "../Campaign/CampaignForm";
import dayjs from "dayjs";
import {Box, Button, Paper, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {getColor, getDuplicatesAndUniques, getEnvName, isEmpty, saveHistory} from "../Utils/CommonUtils";
import {Fragment, useEffect, useState} from "react";
import BasicStep from "./AbTestSteps/BasicStep";
import {Alert, LoadingButton} from "@mui/lab";
import {useCheckAbTestByIdMutation, useSelectAbTestByIdQuery} from "../../core/reducers/abtest";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {useNavigate} from "react-router";
import {POSSIBLE_OPERATORS} from "../Campaign/CampaignSteps/AudienceStep";
import AudienceStep from "./AbTestSteps/AudienceStep";
import {semverBothParams, semverValid, validateLte, validateSingleNumber} from "../Utils/Validator";
import CohortStep from "./AbTestSteps/CohortStep";
import {CohortContext} from "../../core/context/context";
import SummaryStep from "./AbTestSteps/SummaryStep";
import {v4 as uuid} from "uuid";
import BeforeSaveDialog from "./BeforeSaveDialog";
import {prepareCohortsForSave, prepareParametersForSave} from "../CommonSummary";
import {MODE} from "../Campaign/campaignMode";
import {SEARCH_TYPES} from "../../core/reducers/typeFilter";
import ResultDialog from "./ResultDialog";
import CohortSimple from "./AbTestSteps/CohortSimple";
import {useGetAllCampaignsQuery, useSaveCampaignMutation} from "../../core/reducers/campaign";

const currentMoment = dayjs(new Date()).set('second', 0);
const minuteLater = currentMoment.add(1, 'minute')

export class AbTest {
    abTestId = "";
    from = currentMoment;
    till = minuteLater;
    distributionType = "fixed";
    duration = 0;
    groupId = "";
    fickle = false;
    priority = 0;
    reassignCohort = false;
    audiences =
        [
            {
                name: `Audience 1`,
                count: 1,
                parameters: [
                    new ParametersSet(),
                ]
            }
        ];
    cohorts =
        [
            new Cohort(uuid())
        ];
}

const STEPS = {
    BASIC_STEP: {
        name: 'basic',
        index: 0,
        buttonTitle: 'Next',
        keyName: 'BASIC_STEP',
    },
    AUDIENCE_STEP: {
        name: 'audience',
        index: 1,
        buttonTitle: 'Next',
        keyName: 'AUDIENCE_STEP',

    },
    COHORT_STEP: {
        name: 'cohorts',
        index: 2,
        buttonTitle: 'Next',
        keyName: 'COHORT_STEP',
    },
    SUMMARY_STEP: {
        name: 'summary',
        index: 3,
        buttonTitle: 'Create',
        keyName: 'SUMMARY_STEP',
    }
}

const AUDIENCE_PREFIX = "Audience";
const COHORT_PREFIX = "Cohort";


const BASIC_STEP_ERRORS = [];
const AUDIENCE_STEP_ERRORS = [];
const COHORT_STEP_ERRORS = [];

class AudienceError {
    constructor(audienceIndex, paramIndex, fieldValue, fieldName, errorId) {
        this.audienceIndex = audienceIndex;
        this.paramIndex = paramIndex;
        this.fieldValue = fieldValue;
        this.fieldName = fieldName;
        this.errorId = errorId;
    }
}

export class CohortError {
    constructor(cohortIndex, errorType, corrected, uuid) {
        this.cohortIndex = cohortIndex;
        this.errorType = errorType;
        this.corrected = corrected;
        this.uuid = uuid;
    }
}

class Cohort {
    constructor(uuid, id = 'Cohort 1', active = true, limited = false, limit = '-1', weight = '1', count = 1, parameters = '{}', size = -1, creationDate = 0) {
        this.id = id;
        this.active = active;
        this.limited = limited;
        this.limit = limit;
        this.weight = weight;
        this.count = count;
        this.parameters = parameters;
        this.size = size;
        this.uuid = uuid;
        this.creationDate = Math.round(Date.now()/1000)
    }
}

export default function AbTestMiniForm({mode, title, abTest}) {
    const [searchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const envName = getEnvName('env', sourceMode, searchParams);
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(STEPS.SUMMARY_STEP);
    const [currentAbTest, setCurrentAbTest] = useState(abTest);
    const [abTestIdForCheck, setAbTestIdForCheck] = useState();
    const [saveAbTestResponse, setSaveAbTestResponse] = useState({});
    const [basicStepErrors, setBasicStepErrors] = useState(BASIC_STEP_ERRORS);
    const [audienceStepErrors, setAudienceStepErrors] = useState(AUDIENCE_STEP_ERRORS);
    const [cohortStepErrors, setCohortStepErrors] = useState(COHORT_STEP_ERRORS);
    const [beforeSaveDialog, setBeforeSaveDialog] = useState(false);
    const [abTestForSave, setAbTestForSave] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [duplicatedUserGroupError, setDuplicatedUserGroupError] = useState(false);

    const {error: idCheckingError, status: checkingStatus} = useSelectAbTestByIdQuery({
        env: envName,
        id: abTestIdForCheck
    }, {skip: !abTestIdForCheck || mode === MODE.UPDATE});

    const [checkAbtest, checkAbtestResponse] = useCheckAbTestByIdMutation();
console.log('checkAbtestResponse')
console.log('checkAbtestResponse')
console.log('checkAbtestResponse')
console.log(checkAbtestResponse)

    const query = {}
    query.env = 'liveops'
    //query.from = Math.round(new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 7).getTime()/100)*100
    //query.till = Math.round(new Date(new Date().getTime() + (24 * 60 * 60 * 1000) * 365).getTime()/100)*100

    query.from = Math.round(new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 7).setHours(0, 0, 0, 0))/1000
    query.till = Math.round(new Date(new Date().getTime() + (24 * 60 * 60 * 1000) * 365).setHours(0, 0, 0, 0))/1000

    const {
        data: campaigns,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetAllCampaignsQuery(query, {
    });

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setAbTestIdForCheck(currentAbTest.abTestId);
        }, 1000);
        return () => clearTimeout(delayInputTimeoutId);
    }, [currentAbTest.abTestId])

    useEffect(() => {
        if (_existingAbTestIdCheckFailed()) {
            _handleErrorRegistration('abTestId', 'add')
        } else {
            _handleErrorRegistration('abTestId', 'delete')
        }
    }, [checkingStatus])

    useEffect(() => {
        if (_dateCheckFailed()) {
            _handleErrorRegistration('date', 'add')
        } else {
            _handleErrorRegistration('date', 'delete')
        }
    }, [currentAbTest.from, currentAbTest.till])

    useEffect(() => {
        if (isEmpty(currentAbTest.abTestId)) {
            _handleErrorRegistration('abTestIdEmpty', 'add')
        } else {
            _handleErrorRegistration('abTestIdEmpty', 'delete')
        }

        const regexp_validate_name =  /^(\d{4}_\d{2}_\d{2}_AB_[A-Za-z0-9_]+|Config_[A-Za-z0-9_]+)$/
        if(mode != MODE.UPDATE) {
            if(!regexp_validate_name.test(currentAbTest.abTestId)) {

                _handleErrorRegistration('regexp', 'add')
            } else {


                _handleErrorRegistration('regexp', 'delete')
            }
        }

    }, [currentAbTest.abTestId])

    useEffect(() => {
        _validateManualInput();
        // _validateInitialAudienceParameterState();
    }, [currentAbTest])

    const handleChange = (event) => {
        let value = event.target.value;
        if (event.target.name === 'priority') {
            value = +value
            value = (value === 0 || value > 0) ? value : currentAbTest.priority
        }
        if (event.target.name === 'reassignCohort') {
            value = event.target.checked;
        }

        setCurrentAbTest({
            ...currentAbTest,
            [event.target.name]: value,
            'groupId': event.target.name === 'abTestId' || event.target.name === 'groupId' ? value : currentAbTest.groupId
        });
    }

    const handleManualInput = (e, paramIndex, currentAudienceIndex) => {
        let newCurrentAbTest = {...currentAbTest};
        const currentParam = getCurrentParam(newCurrentAbTest, paramIndex, currentAudienceIndex);
        currentParam.paramValue = e.target.value;
        setCurrentAbTest(newCurrentAbTest);
        _validateManualInput();
    }

    const handleAddNewParams = (audienceIndex) => {
        let audiences = [...currentAbTest.audiences];
        let currentAudience = audiences[audienceIndex]
        let newAudienceParameters = currentAudience.parameters;
        newAudienceParameters.push(new ParametersSet())
        audiences[audienceIndex].parameters = newAudienceParameters
        handleChange({
            'target': {
                'name': 'audiences',
                'value': audiences
            }
        })
    }

    const handleDeleteParams = (audienceIndex, paramIndex) => {
        let audiences = [...currentAbTest.audiences];
        let currentAudience = audiences[audienceIndex]
        let newAudienceParameters = currentAudience.parameters;
        newAudienceParameters.splice(paramIndex, 1);
        audiences[audienceIndex].parameters = newAudienceParameters
        handleChange({
            'target': {
                'name': 'audiences',
                'value': audiences
            }
        })
        _sameParametersCheck()
    }

    const handleAddNewAudience = (index) => {
        let newAudiences = [...currentAbTest.audiences];
        let absentNumbers = _findAbsentAudiences(newAudiences);
        let newAudienceCounter = absentNumbers.length > 0 ? absentNumbers[0] : index + 1

        newAudiences.push({
            'name': `${AUDIENCE_PREFIX} ${newAudienceCounter}`,
            'count': newAudienceCounter,
            'parameters': [
                new ParametersSet([], [], null, "", "", "")
            ]
        });
        handleChange({
            'target': {
                'name': 'audiences',
                'value': newAudiences
            }
        })
    }

    const handleAddNewCohort = (index) => {
        let newCohorts = [...currentAbTest.cohorts];
        let absentNumbers = _findAbsentAudiences(newCohorts);
        let newCohortCounter = absentNumbers.length > 0 ? absentNumbers[0] : index + 1


        const cohort = new Cohort(uuid());
        cohort.id = `${COHORT_PREFIX} ${newCohortCounter}`;
        cohort.count = newCohortCounter;
        newCohorts.push(cohort)

        handleChange({
            'target': {
                'name': 'cohorts',
                'value': newCohorts
            }
        })
    }

    const handleDeleteAudience = (index) => {
        if (currentAbTest.audiences.length > 1) {
            let newAudiences = [...currentAbTest.audiences]
            newAudiences.splice(index, 1)
            handleChange({
                'target': {
                    'name': 'audiences',
                    'value': newAudiences
                }
            })
        }
    }

    const handleDeleteCohort = (index) => {
        if (currentAbTest.cohorts.length > 1) {
            let newCohorts = [...currentAbTest.cohorts]
            newCohorts.splice(index, 1)
            handleChange({
                'target': {
                    'name': 'cohorts',
                    'value': newCohorts
                }
            })
            let newCohortStepErrors = [...cohortStepErrors];
            const uuid = newCohortStepErrors.filter(e => e.cohortIndex === index)[0]?.uuid;
            newCohortStepErrors = newCohortStepErrors.filter(e => e.uuid !== uuid);
            setCohortStepErrors(newCohortStepErrors);
        }
    }

    const handleParameterDataChange = (params, value, paramIndex, currentAudienceIndex) => {
        let newAbTest = {...currentAbTest};
        let param = params.filter(p => p.parameterName === value)[0];
        if (isVersionNumericParameter(param)) {
            newAbTest.audiences[currentAudienceIndex].parameters[paramIndex] = new VersionNumericParameterSet();
        } else {
            newAbTest.audiences[currentAudienceIndex].parameters[paramIndex] = new ParametersSet();
        }
        let firstRule = param.allowedRules[0];
        let operator = typeof POSSIBLE_OPERATORS.get(firstRule) === 'string' ? POSSIBLE_OPERATORS.get(firstRule) : POSSIBLE_OPERATORS.get(firstRule)[0];
        getCurrentParam(newAbTest, paramIndex, currentAudienceIndex).parameterData = param;
        newAbTest.audiences[currentAudienceIndex].parameters[paramIndex].operator = operator
        setCurrentAbTest(newAbTest);
        _sameParametersCheck();
    }

    const handleManualInputForOffset = (e, fieldName, paramIndex, currentAudienceIndex) => {
        let newAbTest = {...currentAbTest};
        const currentParam = getCurrentParam(newAbTest, paramIndex, currentAudienceIndex);
        currentParam[fieldName] = e.target.value;
        setCurrentAbTest(newAbTest);
    }

    const handleParameterValueChange = (value, paramIndex, currentAudienceIndex) => {
        let newAbTest = {...currentAbTest};
        let param = getCurrentParam(newAbTest, paramIndex, currentAudienceIndex);
        param.paramValue = value;
        setCurrentAbTest(newAbTest);
    }

    const handleParameterOperatorChange = (value, paramIndex, currentAudienceIndex) => {
        let newAbTest = {...currentAbTest};
        getCurrentParam(currentAbTest, paramIndex, currentAudienceIndex).operator = value;
        setCurrentAbTest(newAbTest);
    }

    const handleVersionNumericParameterChange = (field, value, paramIndex, audienceIndex) => {
        const newCurrentAbTest = {...currentAbTest};

        const verNumParameter = newCurrentAbTest.audiences[audienceIndex].parameters[paramIndex];


        if (verNumParameter.parameterData.allowedRules.includes('VERSION_RANGE')) {
            verNumParameter[field] = value;
            _validateVersion();
        } else if (verNumParameter.parameterData.allowedRules.includes('NUMBER_RANGE')) {
            if (value !== '' && !(value.toUpperCase() !== value.toLowerCase())) {
                value = +value
            }
            verNumParameter[field] = value + '';
            _validateNumber();
        }
        setCurrentAbTest(newCurrentAbTest);
    }

    const handleCohortParameterChange = (cohortIndex, parameterName, parameterValue) => {
        let newCurrentAbTest = {...currentAbTest};

        if (parameterName === 'limited' && parameterValue === false) {
            newCurrentAbTest.cohorts[cohortIndex].limit = -1;
        }
        newCurrentAbTest.cohorts[cohortIndex][parameterName] = parameterValue;
        setCurrentAbTest(newCurrentAbTest)
    }

    const prepareAbTestForSave = async () => {
debugger
        let audiencesForSave = [];
        // cohortList.forEach(audience => {
        //     let audienceForSave = {}
        //     for (let parameterSet of audience.parameters) {
        //         audienceForSave = prepareParametersForSave(audienceForSave, parameterSet)
        //     }
        //     if(Object.keys(audienceForSave).length > 0)
        //         audiencesForSave.push(audienceForSave)
        // })

        //let cohortsForSave = prepareCohortsForSave(currentAbTest.cohorts);
        let cohortsForSave = {};
        cohortList.forEach(cohort => {
            cohortsForSave[cohort.name] = {
                id: cohort.name,
                active: true,
                limit: -1,
                weight: parseInt(cohort.weight),
                parameters: {},
                size: -1,
                userGroups:cohort.userGroup ? cohort.userGroup : []
            }
        })

        let id = cohortList[0].campaign.id.replace(/^(\d{4}(?:_\d{2}_\d{2}|\d{4}))/, '$1_AB');

        //validate id
        debugger
        const response = await checkAbtest({env:'liveops',id: id})
        if(response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
            setAbTestForSave({
                id:id
            });
            return false
        }

        let firstCampaign = cohortList[0].campaign //campaigns.campaigns.filter((item) => item.id == cohortList[0].name)

        let newAbTestForSave = {
            id:id,
            groupId: id,
            priority: 0,
            reassignCohort: false,
            fickle: false,
            distribution: {
                type: 'fixed',
                durationHour: 0.0,
                from: firstCampaign.distribution.from,
                till: firstCampaign.distribution.till
            },
            audiences: firstCampaign.audiences,
            cohorts: cohortsForSave
        }
        setAbTestForSave(newAbTestForSave);
        return true;
    }


    const handleDateChange = (date, field) => {
        date = date.set('second', 0)
        let newAbTest = {...currentAbTest};
        newAbTest[field] = dayjs(new Date(date.valueOf()));
        // newAbTest[field + 'Instant'] = Date.parse(date.toISOString().replace('Z', newAbTest.timeZone));
        setCurrentAbTest(newAbTest)
    }

    const handleNextStep = async () => {
        setShowAlert(false
        )
        if (activeStep !== STEPS.SUMMARY_STEP) {
            setActiveStep(_getNextStep(activeStep))
        } else {
            let result = await prepareAbTestForSave();
            if(result) setBeforeSaveDialog(true);
            else {
                setShowAlert(true)
            }
        }
    };
    const handleBack = () => {
        setActiveStep(_getPreviousStep(activeStep));
    };

    const _getNextStep = (currentStep) => {
        return STEPS[Object.keys(STEPS).filter(key => STEPS[key].index - 1 === currentStep.index)[0]]
    }
    const _getPreviousStep = (currentStep) => {
        return STEPS[Object.keys(STEPS).filter(key => STEPS[key].index + 1 === currentStep.index)[0]]
    }

    const _existingAbTestIdCheckFailed = () => {
        return mode !== MODE.UPDATE && checkingStatus === 'fulfilled' && idCheckingError?.status !== 404
    }
    const _beforeExistingIdCheckStart = () => {
        return isEmpty(currentAbTest.abTestId) || abTestIdForCheck !== currentAbTest.abTestId
    }

    const _dateCheckFailed = () => {
        if (currentAbTest.from.hasOwnProperty('$isDayjsObject') && currentAbTest.till.hasOwnProperty('$isDayjsObject')) {
            return currentAbTest.from.isAfter(currentAbTest.till) || currentAbTest.from.isSame(currentAbTest.till)
        }
        return false;
    }

    const _sameParametersCheck = () => {
        let sameParamsErrors = [];
        currentAbTest.audiences.forEach((a, aIndex) => {
            let duplicatesAndUniques =
                getDuplicatesAndUniques(currentAbTest.audiences[aIndex].parameters.map(p => p.parameterData.parameterName));
            duplicatesAndUniques.duplicates.forEach(duplicate => {
                currentAbTest.audiences[aIndex].parameters.forEach((p, pIndex) => {
                    if (p.parameterData.parameterName === duplicate) {
                        sameParamsErrors.push(new AudienceError(aIndex, pIndex, duplicate, "paramNameError"));
                    }
                })
            });
        })
        _handleAudienceErrorRegistration('sameFieldError', sameParamsErrors)
    }

    const _validateVersion = () => {
        let errors = [];
        currentAbTest.audiences.forEach((a, aIndex) => {
                a.parameters.forEach((p, pIndex) => {
                    if (p.parameterData.allowedRules.includes('VERSION_RANGE')) {
                        if (!semverValid(p.paramFromValue)) {
                            errors.push(new AudienceError(aIndex, pIndex, '', 'paramFromValue'))
                        }
                        if (!semverValid(p.paramToValue)) {
                            errors.push(new AudienceError(aIndex, pIndex, '', 'paramToValue'))
                        }
                        if (!semverBothParams(p.paramFromValue, p.paramToValue)) {
                            errors.push(new AudienceError(aIndex, pIndex, '', 'toAndFromValueError'))
                        }
                    }
                })
            }
        )
        _handleAudienceErrorRegistration('versionError', errors)
    }

    const _validateNumber = () => {
        let errors = [];
        currentAbTest.audiences.forEach((a, aIndex) => {
                a.parameters.forEach((p, pIndex) => {
                    if (p.parameterData.allowedRules.includes('NUMBER_RANGE')) {
                        if (!validateSingleNumber(p.paramFromValue)) {
                            errors.push(new AudienceError(aIndex, pIndex, '', 'paramFromValue'))
                        }
                        if (!validateSingleNumber(p.paramToValue)) {
                            errors.push(new AudienceError(aIndex, pIndex, '', 'paramToValue'))
                        }
                        if (!validateLte(p.paramFromValue, p.paramToValue)) {
                            errors.push(new AudienceError(aIndex, pIndex, '', 'toAndFromValueError'))
                        }
                    }
                })
            }
        )
        _handleAudienceErrorRegistration('numberError', errors)
    }

    const _validateManualInput = () => {
        let manualInputErrors = [];
        currentAbTest.audiences.forEach((a, aIndex) => {
                a.parameters.forEach((p, pIndex) => {
                    if (!p.parameterData.allowedRules?.includes('VERSION_RANGE') &&
                        !p.parameterData.allowedRules?.includes('NUMBER_RANGE') && isEmpty(p.paramValue)) {
                        manualInputErrors.push(new AudienceError(aIndex, pIndex, '', 'paramValue'))
                    }
                })
            }
        )
        _handleAudienceErrorRegistration('paramValueError', manualInputErrors)
    }

    const _validateInitialAudienceParameterState = () => {
        let initialErrors = [];
        currentAbTest.audiences.forEach((a, aIndex) => {
            a.parameters.forEach((p, pIndex) => {
                if (!p.parameterData.allowedRules?.includes('VERSION_RANGE') &&
                    !p.parameterData.allowedRules?.includes('NUMBER_RANGE') && isEmpty(p.paramValue)) {
                    initialErrors.push(new AudienceError(aIndex, pIndex, '', 'paramValue'))
                }
                if (!p.parameterData.parameterName) {
                    initialErrors.push(new AudienceError(aIndex, pIndex, '', 'parameterName'))
                }
            })
        })
        _handleSeveralAudienceErrorsRegistration(['paramValueError', 'parameterNameError'])
    }

    const _handleCohortErrorRegistration = (error) => {
        let newStepErrors = [...cohortStepErrors];
        newStepErrors = newStepErrors
            .filter(e => e.uuid !== error.uuid || e.errorType !== error.errorType);
        newStepErrors.push(error)
        setCohortStepErrors(newStepErrors)
    }

    const _handleAudienceErrorRegistration = (errorType, error) => {
        let newStepErrors = [...audienceStepErrors]

        newStepErrors = newStepErrors.filter(i => !i.hasOwnProperty(errorType))
        newStepErrors.push({[errorType]: error});
        setAudienceStepErrors(newStepErrors)
    }

    const _handleSeveralAudienceErrorsRegistration = (errorTypes) => {
        let newStepErrors = [...audienceStepErrors];

        newStepErrors = newStepErrors.filter(stepError => {
            errorTypes.forEach(errorType => {
                return stepError === errorType;
            })
        });
        errorTypes.forEach(errorType => {
            newStepErrors.push({[errorType]: errorType});
        })
        setAudienceStepErrors(newStepErrors)

    }

    const _handleErrorRegistration = (errorName, action = 'delete') => {
        let newStepErrors = [...basicStepErrors]

        if (action === 'delete') {
            newStepErrors = newStepErrors.filter(e => e !== errorName)
        } else {
            if (!newStepErrors.includes(errorName)) {
                newStepErrors.push(errorName)
            }
        }
        setBasicStepErrors(newStepErrors)
    }

    const _findAbsentAudiences = (audiences) => {
        let numbers = audiences.map(a => a.count).sort();
        let absentNumbers = [];
        for (let i = 1; i < numbers[numbers.length - 1]; i++) {
            if (!numbers.includes(i)) {
                absentNumbers.push(i);
            }
        }
        return absentNumbers;
    }

    const _basicStepHasErrors = () => {
        if (activeStep === STEPS.BASIC_STEP) {
            return basicStepErrors.length > 0 ||
                currentAbTest.abTestId.toLowerCase().includes('clone') ||
                (mode !== MODE.UPDATE && (checkingStatus !== 'rejected' || abTestIdForCheck !== currentAbTest.abTestId));
        }
    }

    const _audienceStepHasErrors = () => {
        if (activeStep === STEPS.AUDIENCE_STEP) {
            return audienceStepErrors
                .flatMap(i => Object.keys(i).map(j => i[j])).filter(i => i.length > 0).length > 0;
        }
    }
    const _cohortStepHasErrors = () => {
        if (activeStep === STEPS.COHORT_STEP) {
            return cohortStepErrors.filter(e => !e.corrected).length > 0 || duplicatedUserGroupError;
        }
    }

    const [hasErrors, setHasErrors] = useState(false)

    const STEP_VALIDATION = {
        [STEPS.BASIC_STEP.keyName]: _basicStepHasErrors(),
        [STEPS.AUDIENCE_STEP.keyName]: _audienceStepHasErrors(),
        [STEPS.COHORT_STEP.keyName]: _cohortStepHasErrors(),
    }

    const [cohortList, setCohortList] = useState([
        { name: 'A', weight: 1, campaign: null, removable: false },
        { name: 'B', weight: 1, campaign: null, removable: false }
    ]);

    const cohortCampaignsFilled = cohortList.filter(item => item.campaign==null).length==0

    return (
        <>
            <Box display={'flex'} justifyContent={'center'}>
                <Box component="main" maxWidth="lg" sx={{mb: 4}} display={'grid'}>
                    <Paper
                        variant="outlined" sx={{my: {xs: 3, md: 2}, p: {xs: 2, md: 3}}}>
                        <Typography component="h1" variant="h4" align="center">
                        <span
                            style={{color: getColor(mode)}}>{mode.toUpperCase()
                        + (mode === MODE.CREATE ? ' AB test' : '')} </span>{title ? title : ''}
                        </Typography>

                        <CohortSimple cohortList={cohortList} setCohortList={setCohortList} setHasErrors={setHasErrors} />

                        {showAlert&&<Alert severity="error">AB test with name <strong>{abTestForSave.id}</strong> exists.</Alert>}

                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>

                            <Button
                                startIcon={<NavigateBeforeIcon/>}
                                color={"warning"}
                                variant={"contained"}
                                onClick={() => navigate(`/variants?env=liveops`)}>
                                Back
                            </Button>

                            <Box>
                                <LoadingButton
                                    loading={checkAbtestResponse.isLoading}
                                    loadingPosition={'end'}
                                    endIcon={<NavigateNextIcon/>}
                                    variant="contained"
                                    onClick={handleNextStep}
                                    disabled={!cohortCampaignsFilled||hasErrors}

                                >
                                    {activeStep?.buttonTitle}
                                </LoadingButton>
                            </Box>
                        </Box>
                        <BeforeSaveDialog
                            beforeSaveDialogOpen={beforeSaveDialog}
                            setBeforeSaveDialogOpen={setBeforeSaveDialog}
                            abTestForSave={abTestForSave}
                            setSaveAbTestResponse={setSaveAbTestResponse}
                            updateCampaigns={true}
                            cohorts={cohortList}
                        />
                        <ResultDialog
                            saveAbTestResponse={saveAbTestResponse}
                        />
                    </Paper>
                </Box>
            </Box>
        </>
    )

}