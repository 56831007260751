import {
    Box, Button, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../../Loading";
import {Alert} from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import TransferCheck from "./TransferCheck";
import {useGetAllCampaignsQuery} from "../../../core/reducers/campaign";

export const CampaignTransferDialog = ({setErrorAfterCheck,setTransferInProgress,transferInProgress,completedTransferList, transferResponse, getFromCompletedList, dateFilter, selectedList, openResultDialog, onCloseTransferDialog, chooseTransferDialogTitle, env2, errorAfterCheck}) => {


    const query = {}
    query.env = env2
    query.from = Math.round(new Date(dateFilter.from).setHours(0,0,0,0)/1000);
    query.till = Math.round(new Date(dateFilter.till).setHours(23,59,59,999)/1000);

    const {
        data: campaigns,

    } = useGetAllCampaignsQuery(query, {
        //pollingInterval: 5000,
    });

    const getWarningsAlert = (item) => {
        debugger
        if(campaigns==undefined) return ''
        const campaign = campaigns.campaigns.filter(cItem => cItem.id==item)
        if(campaign.length==0) return ''
        if(!campaign[0].hasOwnProperty('warnings') || campaign[0].warnings==null || campaign[0].warnings.length==0) return ''

        return <Alert  severity={"warning"}>{campaigns.campaigns.filter(cItem => cItem.id==item)[0].warnings.map(_warning => (<div>{_warning}</div>))}</Alert>

    }

    return <Dialog
        open={openResultDialog}
        onClose={() => {onCloseTransferDialog()}
        }
        maxWidth="md"
        fullWidth={true}
    >
        <DialogTitle textAlign={'center'}>
            {chooseTransferDialogTitle(errorAfterCheck)}
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={() => {onCloseTransferDialog()}}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent>
            <DialogContentText>
                <Loading isLoading={completedTransferList.length === 0} display={'block'}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>Campaign</b>
                                </TableCell>
                                <TableCell align={'center'}>
                                    <b>In Queue</b>
                                </TableCell>
                                <TableCell align={'center'}>
                                    <b>Status</b>
                                </TableCell>
                                {/*{env2=='liveops'&&<TableCell align={'center'}>*/}
                                {/*    <b>Warnings {env2}</b>*/}
                                {/*</TableCell>}*/}

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {selectedList.map((item, index) => {
                                return <TableRow>
                                    <TableCell style={{maxWidth:'300px'}}>
                                        {item}
                                        {getFromCompletedList(item)?.hasOwnProperty("error") && <Alert  severity="error">{getFromCompletedList(item).error}</Alert>}

                                    </TableCell>
                                    <TableCell>
                                        <Box align={'center'}>
                                            <Loading isLoading={!getFromCompletedList(item)?.hasOwnProperty('transferred')}
                                                     display={'block'}>
                                                {getFromCompletedList(item)?.transferred ? <DoneIcon/> : <CancelIcon/>}

                                            </Loading>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {getFromCompletedList(item)?.transferId &&
                                            <TransferCheck
                                                transferInProgress={transferInProgress}
                                                setTransferInProgress={setTransferInProgress}
                                                env={env2}
                                                index={index}
                                                setErrorAfterCheck={setErrorAfterCheck}
                                                transferId={getFromCompletedList(item)?.transferId}/>}
                                    </TableCell>
                                    {/*{env2=='liveops'&&<TableCell>*/}
                                    {/*    {getWarningsAlert(item)}*/}
                                    {/*</TableCell>}*/}

                                </TableRow>
                            })}
                        </TableBody>

                    </Table>
                </Loading>

            </DialogContentText>
        </DialogContent>
        <DialogActions>

            <Button variant="contained"
                    loading={transferResponse.isLoading}
                    onClick={() => {
                        onCloseTransferDialog()
                    }}
            >
                Close
            </Button>
        </DialogActions>
    </Dialog>

}