import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    FormControl, FormHelperText, InputLabel,
    Link,
    MenuItem,
    Select,
    Tooltip, Typography
} from "@mui/material";
import CampaignDetailsDialog from "../../Dialogs/CampaignDetailsDialog";
import CampaignJsonDialog from "../../Dialogs/CampaignJsonDialog";
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/ContentCopy';
import {LoadingButton} from "@mui/lab";
import {CampaignItemContext} from "../../../core/context/context";
import dayjs from "dayjs";

import clipboardCopy from 'clipboard-copy';
import {SnackBarContext} from "../../../core/context/snackBarProvider";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {statusItems} from "./PublisherABTests";
import Audience from "../Audience";
import Cohort from "./Cohort";
import {useSetAbtestStatusMutation} from "../../../core/reducers/abtest";
import {GROUP_ADMIN, isProdAdmin, isEmpty} from "../../Utils/CommonUtils";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import HistoryDialog from "../../Dialogs/HistoryDialog";

export default function AbtestItem({item, showRainbow}) {
    const {types, type, items, onSelectItem, selectedList, env, createDisabled} = useContext(CampaignItemContext)
    const [historyOpen, setHistoryOpen] = useState(false);

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const [open, setOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [jsonOpen, setJsonOpen] = useState(false);

    const [showConfirmStatusChange, setShowConfirmStatusChange] = useState(false)
    const [status, setStatus] = useState('')
    const [prevStatus, setPrevStatus] = useState('')

    const statusList = statusItems;

    const [selected, setSelected] = useState(selectedList != undefined ? selectedList.indexOf(item.id) >= 0 : false)
    const [selectedCohort, setSelectedCohort] = useState('')

    const [timeLeftTill, setTimeLeftTill] = useState()
    const [timeLeftFrom, setTimeLeftFrom] = useState()
    const sourceMode = useSelector((state) => state.data.SourceMode);
    const appMode = sourceMode.mode;

    const navigate = useNavigate();



    const userSession = useSelector((state) => state.data.UserSession);


    const isPermanent = () => {
        return item.distribution.till == '32503680000'
    }
    useEffect(() => {
        setStatus(item.status);
        setPrevStatus(item.status);
    }, [item.status, item.id]);

    const handleCopyClick = (text) => {
        clipboardCopy(text);

        addAlert({text: 'copied', type: 'success'})

    };

    const handleSelectCohort = (event) => {
        setSelectedCohort(event.target.value)
    }

    const handleSelect = (event) => {
        onSelectItem({item: item})
        setSelected(!selected)
    }

    const [
        saveStatus,
        saveStatusResponse
    ] = useSetAbtestStatusMutation()

    const confirmSaveStatus = async () => {
        if(!canChangeStatus()) return false;

        let data = {
            environment: env,
            id: item.id,
            status: status
        }

        if(status=='COMPLETED') {
            if(selectedCohort == '' || selectedCohort == 'undefined' || selectedCohort == undefined ) return false;
            data['cohort'] = selectedCohort
        } else {
            //data['cohort'] = null
        }

        const response = await saveStatus(data)

        if (response.hasOwnProperty('data') && response.data.status.toLowerCase() == status.toLowerCase())
            addAlert({text: 'Status changed', type: 'success'})
        else {
            let message = 'Error';
            if(response.hasOwnProperty('error')) message += ' '  + response?.error?.data?.message
            addAlert({text: message, type: 'error'})
        }

        setShowConfirmStatusChange(false)
        setPrevStatus(status)
    }
    const cancelSaveStatus = () => {
        setStatus(prevStatus)
        setShowConfirmStatusChange(false)
    }
    const handleChangeStatus = (event) => {
        if(!isProdAdmin(env, userSession)) return false;

        setStatus(event.target.value)
        setShowConfirmStatusChange(true)
    }


    const formatdate = (timestamp) => {
        let d = dayjs(timestamp * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')
        return d;
    }

    const getTimeLeft = (targetDate) => {
        const now = new Date();
        const target = new Date(targetDate * 1000);


        const timeDifference = target - now;


        if (timeDifference <= 0) {
            return "0d 00:00:00";
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return `${days}d ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }


    const timeClassName = () => {
        if(!showRainbow) return ''

        let current_time = new Date().getTime()/1000;

        if(current_time < (item.distribution.from)) return 'aqua'; // #99cedf - до наступления анонса

        if(
            current_time < (item.distribution.till) &&
            current_time >= (item.distribution.from)
        ) return 'green'; // #2f8f2c - активная фаза

        if(current_time >= (item.distribution.till )) return 'red'; //     #e65959 - после extra time

    }

    useEffect(() => {
        let timeleftInterval = setInterval(() => {
            setTimeLeftTill(getTimeLeft(item.distribution.till))
            setTimeLeftFrom(getTimeLeft(item.distribution.from))
        }, 1000)
        return () => {
            clearInterval(timeleftInterval);
        };
    });

    useEffect(() => {
        setSelected(selectedList != undefined ? selectedList.indexOf(item.id) >= 0 : false)
    }, [selectedList, item.id])

    // function getEnvForEdit() {
    //     let env;
    //     if (rightPanel) {
    //         if (localStorage.getItem('env2Name')) {
    //             env = localStorage.getItem('env2Name')
    //         } else {
    //             env = 'staging'
    //         }
    //     } else {
    //         if (localStorage.getItem('envName') === 'PROD') {
    //             env = 'liveops'
    //         } else {
    //             env = localStorage.getItem('envName');
    //         }
    //     }
    //     return env;
    // }
    const handleCloneCampaignClick = () => {
        navigate(`/creator/${appMode}/${item.id}?env=${env}&clone`)
    }

    const handleEditCampaignClick = () => {
        navigate(`/creator/${appMode}/${item.id}?env=${env}`)
    }

    const sortByCreationDate = (cohorts) => {
        return cohorts.sort((a, b) => {
            const dateA = new Date(a.creationDate);
            const dateB = new Date(b.creationDate);

            return dateA - dateB;
        });
    }

    const sortedCohorts = sortByCreationDate(Array.from(new Map(Object.entries(item.cohorts)).values()));

    const canChangeStatus = () => {
        if(env == "production" && prevStatus=="DEACTIVATED") return false;
        if(env == "production" && prevStatus=="COMPLETED" && status != "DEACTIVATED") return false;

        return true;
    }

    const getChangeStatusPopup = () => {

        if(!canChangeStatus()) {
            return <>You can not change DEACTIVATED status in production: <a target="_blank" href="https://belka-games.atlassian.net/wiki/spaces/BAC/pages/3992617217/Mephisto+Use+Cases#%D0%A1%D1%82%D0%B0%D1%82%D1%83%D1%81%D1%8B-%D0%B0%D0%B1-%D1%82%D0%B5%D1%81%D1%82%D0%BE%D0%B2">docs</a></>
        }


        if(status=="COMPLETED") return <div>
            <FormControl fullWidth sx={{mt:1}} >
                <InputLabel>Select winning cohort</InputLabel>
                <Select
                    value={selectedCohort}
                    label="Select winning cohort"
                    onChange={handleSelectCohort}
                >
                    {Object.keys(item.cohorts).map((cohort) => (
                        <MenuItem value={cohort}>{cohort}</MenuItem>
                    ))}
                </Select>
            </FormControl>

        </div>

    }

    const formatLink = (link) => {
        if(link.indexOf('http')!==0) {
            return 'https://'+link
        }
        return link
    }

    return (
        <>
            <Card className="campaign-item" key={item.id} style={{marginBottom: '3px'}}>
                <CardContent>


                    <div className="campaign-item__body" onClick={(e) => {
                        const elementClicked = e.target.className;

                        if (
                            typeof e.target.className != "object" &&
                            e.target.tagName.toLowerCase() != "button" &&
                            e.target.tagName.toLowerCase() != "input" &&
                            !elementClicked.includes('MuiChip-label') &&
                            !elementClicked.includes('campaign-actions') &&
                            !elementClicked.includes('campaign-item__checkbox') &&
                            !elementClicked.includes('campaign-status')
                        ) {
                            setShowDetails(!showDetails);
                        }
                    }}>
                        <div >
                            <div className="campaign-item__header">
                                <div className="campaign-item__checkbox">
                                    {onSelectItem != undefined && <Checkbox onChange={handleSelect} checked={selected}/>}
                                </div>

                                <div className="campaign-item__name">

                                    <div >
                                        <span onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopyClick(item.id)
                                        }}>{item.id}</span>

                                    </div>

                                </div>


                                {env!=='production' && <div className="campaign-actions">
                                    <div>
                                        {!createDisabled ?
                                            <Link
                                                onClick={handleEditCampaignClick}
                                                  className="campaign-edit__link">
                                                <Tooltip title={"edit"}
                                                         placement="right">
                                                    <EditIcon fontSize="small"/>
                                                </Tooltip>
                                            </Link> :
                                            <Tooltip title={"editing disabled"}
                                                     placement="right">
                                                <EditIcon fontSize="small"/>
                                            </Tooltip>
                                        }
                                    </div>

                                    <div>
                                        {!createDisabled ?
                                            <Link
                                                onClick={handleCloneCampaignClick}
                                                  className="campaign-edit__link">
                                                <Tooltip title={"clone"} placement="right">
                                                    <CopyIcon fontSize="small"/>
                                                </Tooltip>
                                            </Link>
                                            :
                                            <Tooltip title={"create disabled"} placement="right">
                                                <CopyIcon fontSize="small"/>
                                            </Tooltip>
                                        }



                                    </div>
                                </div>}

                                <div className={"campaign-timewrap "+ timeClassName()}>
                                    <div className="campaign-timeblock">
                                        <div className="campaign-item__starttime">
                                            <Tooltip
                                                title="distribution from">
                                                <span>{formatdate(item.distribution.from)}</span>
                                            </Tooltip>
                                        </div>
                                        <div className="campaign-item__countdown-timer">
                                            <Tooltip
                                                title="timeleft From">
                                                <span>{timeLeftFrom}</span>
                                            </Tooltip>
                                        </div>

                                    </div>
                                    <div className="campaign-timeblock">

                                        <div className="campaign-item__endtime">
                                            <Tooltip
                                                title="distribution till">
                                                <span>{formatdate(item.distribution.till)}</span>
                                            </Tooltip>
                                        </div>
                                        <div className="campaign-item__countdown-timer">
                                            <Tooltip
                                                title="timeleft till">
                                                <span>{!isPermanent()?timeLeftTill:<>-</>}</span>
                                            </Tooltip>
                                        </div>

                                    </div>
                                </div>

                                <div className="campaign-status">
                                    <FormControl sx={{width: 90}}
                                                 size="small"
                                    >
                                        <Select
                                            className={status}

                                            value={status}
                                            onChange={handleChangeStatus}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                return true;
                                            }}
                                        >
                                            {statusList.map((item, index) => <MenuItem key={index} className={item}
                                                                                       value={item}>{item}</MenuItem>)}

                                        </Select>
                                    </FormControl>
                                </div>


                            </div>


                        </div>

                        {(item.hasOwnProperty('winningCohortId') && item.winningCohortId!="undefined" && item.winningCohortId!=null) && <div className="win-cohort">
                            <small>Win: <strong>{item.winningCohortId}</strong></small>
                        </div>}

                        {showDetails && item.cohorts&& <div className="campaign-details">

                            <Divider/>
                            <Typography className="abtest-cohorts">
                                Cohorts
                            </Typography>
                            <div className="campaign-audience">
                                {
                                    sortedCohorts.map((cohort, index) => (
                                        <Cohort cohort={cohort}>{cohort.id}: </Cohort>))
                                }
                            </div>

                            <Divider/>
                            <Typography className="abtest-cohorts">
                                Audiences
                            </Typography>

                            <div className="campaign-audience">
                                {item.audiences.map((audience, index) => (
                                    <Audience audience={audience}>A{(index + 1)}: </Audience>
                                ))}
                            </div>
                            {item.fickle==true&&<div><small>Check audiences Every Time (fickle)</small></div>}

                            <div className="campaign-item__footer">
                                <Button variant="outlined" onClick={() => (setJsonOpen(true))} sx={{mr: 1}}>
                                    JSON
                                </Button>

                                <Button variant="outlined" onClick={() => (setHistoryOpen(true))} sx={{mr: 1}}>
                                    History
                                </Button>

                                {item.hasOwnProperty('metaData')&&item.metaData!=null&&item.metaData.hasOwnProperty('link')&&!isEmpty(item.metaData.link)&&<Button color={"error"} variant="outlined"
                                        onClick={() => window.open(formatLink(item.metaData.link), '_blank')}
                                        sx={{mr: 1, pl:1}}>
                                    <WorkspacesIcon sx={{mr:1}} /> ASANA
                                </Button>}

                            </div>
                        </div>}
                    </div>

                </CardContent>
            </Card>

            <CampaignDetailsDialog item={item} open={open} setOpen={setOpen}></CampaignDetailsDialog>
            <CampaignJsonDialog item={item} open={jsonOpen} setOpen={setJsonOpen}></CampaignJsonDialog>
            {(historyOpen) &&
                <HistoryDialog key={`h`+item.id} env={env} item={item} open={historyOpen} setOpen={setHistoryOpen}></HistoryDialog>}
            <Dialog
                open={showConfirmStatusChange}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Changing status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You want to change status from <strong>{prevStatus}</strong> to <strong>{status}</strong>
                        <br/>for <strong>{item.id}</strong>

                        <div>{getChangeStatusPopup()}</div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelSaveStatus}>Cancel</Button>
                    {canChangeStatus()&&<LoadingButton variant="contained" onClick={confirmSaveStatus}
                                   loading={saveStatusResponse.isLoading}>
                        Save
                    </LoadingButton>}
                </DialogActions>
            </Dialog>
        </>
    )

}